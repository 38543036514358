import { useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { openApp as _openApp } from '@/state'
import Modal from '@/components/modal'
import { DOMIds } from '@/tools'

// 微应用挂载
const MicroApps = (props) => {
  const { name } = props
  const [openApp] = useRecoilState(_openApp) // 打开的应用

  // 偏好设置是否打开状态
  const isOpen = useMemo(() => {
    return openApp?.includes(name)
  }, [openApp])

  return (
    <Modal
      open={isOpen}
      getContainer={`#${DOMIds.main}`}
      title={<h3>{name}</h3>}
      isMaximized={true}
      appId={name}
    >
      <micro-app {...props} />
    </Modal>
  )
}

export default MicroApps
