import { useState, memo, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Flex, Typography, Modal, Space, Button, Form, Input } from 'antd'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import { useRecoilState } from 'recoil'
import { mainSize as _mainSize, folderData as _folderData, isOpenFolder as _isOpenFolder, isMobileDevice as _isMobileDevice, gridInfo as _gridInfo, deleting as _deleting, cardData as _cardData, dragging as _dragging } from '@/state'

import { onWindowSize, smallScreenWidthThreshold, DOMIds } from '@/tools'
import Carousel, { addingTheTrimmings } from '@/components/carousel'
import { DraggableMenuCard } from '@/components/card'
import Folder from '@/components/folder'
import FormItmeRender from '@/components/formItmeRender'
import ProblemExplanation from '@/components/problemExplanation'
import IconOrColor from '@/components/iconOrColor'
import { userCardDelBind, userCardSetCard } from '@/api'


const Main = styled.main`
  height: ${props => props.$height};
  position: relative;
  z-index: ${props => props.$dragging ? 3 : 1};
`

const CardBox = styled.section`
  user-select: none;
`

const FolderBox = styled.div`
  background-color: #ffffff40;
  border-radius: 10px;

  padding: 4px;
  display: grid;
  grid-gap: .5px;

  overflow-x: auto;
  scrollbar-width: none; /* 针对 WebKit 浏览器 */
  -ms-overflow-style: none; /* IE 10+ */
  -webkit-overflow-scrolling: none; /* Chrome, Safari */

  :global{
    .ant-spin-nested-loading{
      position: relative;

      @media (max-width: 500px) {
        top: -4px;
      }
    }
  }

  &:hover{
    outline: 2px solid #6378ff;
    transform: translate(0, -3px) scale(1.05);
    transition: all .7s;
  }

  height: ${props => props.$cardSezi}px;
  grid-template-columns: repeat(3, ${props => props.$minCardSezi}px);
  grid-template-rows: repeat(${props => Math.ceil(props.$length / 3)}, ${props => props.$minCardSezi}px);
`

export default memo((props) => {
  const { background, height = 'calc(100vh - 90px)', takeOut } = props

  const [mainSize, setMainSize] = useRecoilState(_mainSize) // 内容区域-尺寸
  const [, setFolderData] = useRecoilState(_folderData) // 文件夹数据
  const [isOpenFolder, setIsOpenFolder] = useRecoilState(_isOpenFolder) // 是否打开文件夹
  const [isMobileDevice] = useRecoilState(_isMobileDevice) // 是否移动端
  const [gridInfo, setGridInfo] = useRecoilState(_gridInfo) // 布局信息

  const [gridColumnGap, setGridColumnGap] = useState('50px') // 列间距
  const [, setGridRowGap] = useState('50px') // 行间距 gridRowGap
  const [cardData] = useRecoilState(_cardData) // 格式化后的卡片数据

  const [deleting, setDeleting] = useRecoilState(_deleting) // 是否处于删除状态
  const [dragging] = useRecoilState(_dragging) // 是否处于拖拽状态

  const [setConfirmLoading, setSetConfirmLoading] = useState(false) // 修改窗口加载状态
  const [modal, contextHolder] = Modal.useModal()
  const [form] = Form.useForm()


  // 小屏宽度范围
  const isSmallScreen = useMemo(() => {
    return mainSize.width < smallScreenWidthThreshold
  }, [smallScreenWidthThreshold, mainSize.width])

  const cardSezi = useMemo(() => {
    if (mainSize.width < 400 ) {
      return 50
    } else if (mainSize.width < 450) {
      return 55
    } else if (mainSize.width < 500) {
      return 60
    } else {
      return 70
    }
  }, [isSmallScreen, mainSize.width])

  const minCardSezi = useMemo(() => {
    if (mainSize.width < 400 ) {
      return 14
    } else if (mainSize.width < 450) {
      return 16
    } else if (mainSize.width < 500) {
      return 17
    } else {
      return 20
    }
  }, [isSmallScreen, mainSize.width])

  // 监听窗口尺寸变化
  useEffect(() => {
    const resizeObserver = onWindowSize(`#${DOMIds.main}`, setMainSize)

    return () => {
      resizeObserver?.disconnect?.()
    }
  }, [])

  // 按照用户屏幕尺寸初始化格子
  useEffect(() => {
    const { height, width } = mainSize

    let colCount = 0
    let rowCount = 0

    // 列数计算
    if (width > 1600 || width === 1600) { // xxl	屏幕 ≥ 1600px
      colCount = 10
    } else if (width > 1200 || width === 1200) { // xl	屏幕 ≥ 1200px
    colCount = 8
    } else if (width > 992 || width === 992) { // lg	屏幕 ≥ 992px
      colCount = 6
    } else if (width > 768 || width === 768) { // md	屏幕 ≥ 768px
      colCount = 4
    } else if (width > 576 || width === 576) { // sm	屏幕 ≥ 576px
      colCount = 4
    } else { // xs	屏幕 < 576px
      colCount = 4
    }

    const setGap = (val) => {
      setGridColumnGap(val)
      setGridRowGap(val)
    }

    // 间隙
    if (width > 1600 || width === 1600) { // xxl	屏幕 ≥ 1600px
      setGap('50px')
    } else if (width > 1200 || width === 1200) { // xl	屏幕 ≥ 1200px
      setGap('50px')
    } else if (width > 992 || width === 992) { // lg	屏幕 ≥ 992px
      setGap('40px')
    } else if (width > 768 || width === 768) { // md	屏幕 ≥ 768px
      setGap('40px')
    } else if (width > 576 || width === 576) { // sm	屏幕 ≥ 576px
      setGap('30px')
    } else { // xs	屏幕 < 576px
      setGap('30px')
    }

    // 行数计算
    if (height > 750 || height === 750) {
      rowCount = 6
    } else if (height > 620 || height === 620) {
      rowCount = 5
    } else if (height > 500 || height === 500) {
      rowCount = 4
    } else {
      rowCount = 3
    }

    setGridInfo({ colCount, rowCount }) // 存储布局信息
  }, [mainSize]) // sort

  // 点击文件夹
  const openFolder = (data) => {
    setFolderData(data)
    setIsOpenFolder(true)
  }

  // 右键菜单
  const menuData = [{ label: '修改', value: 1 }, { label: '删除', value: 2 }, { label: '批量删除', value: 3 }]

  // 菜单点击事件
  const onMenuItemClick = (menuItem, options) => {
    switch(menuItem.value) {
      case 1: // 修改
        onSet(options.v)
        break

      case 2: // 删除
        onDel(options.v)
        break

      case 3: // 批量删除
        setDeleting(true)
        break

      default:
        break
    }
  };

  // 删除
  const onDel = async (v) => {
    try {
        await userCardDelBind({ _id: v._id })
        window?.__app__?.refreshDesktop?.()
    } catch (error) {
        console.error(error)
    }
  }

  const onSet = async (v) => {
    try {
      const currentCardData = v.cardData
      const MODAL_CLASS_NAME = 'custom-modal-wrapper'

      const submit = async (fd) => {
        const { customizeTitle, customizeDescription, customizeIcon } = fd // sort classIds

        setSetConfirmLoading(true)
        try {
          const req = {
            _id: v._id,
            cardId: v.cardId,
            classIds: v.classIds,
            sort: 1,
            customizeTitle: customizeTitle === currentCardData.title ? '' : customizeTitle,
            customizeDescription: customizeDescription === currentCardData.description ? '' : customizeDescription,
            customizeIcon: (customizeIcon === currentCardData.appleTouchIcon || customizeIcon === currentCardData.shortcutIcon || customizeIcon === currentCardData.icon) ? '' : customizeIcon,
          }
          await userCardSetCard(req)
          window?.__app__?.refreshDesktop?.()
          modalRef.destroy(); // 成功后关闭弹窗
        } catch (error) {
          console.error(error)
        }
        setSetConfirmLoading(false)
      }

      // 设置表单的初始值
      form.setFieldsValue({
        customizeTitle: v?.customizeTitle || currentCardData?.title,
        customizeIcon: v?.customizeIcon || currentCardData?.appleTouchIcon || currentCardData?.shortcutIcon || currentCardData?.icon,
        customizeDescription: v?.customizeDescription || currentCardData?.description,
      })

      const reset = () => {
        form.setFieldsValue({
          customizeTitle: currentCardData?.title,
          customizeIcon: currentCardData?.appleTouchIcon || currentCardData?.shortcutIcon || currentCardData?.icon,
          customizeDescription: currentCardData?.description,
        })
      }

      const modalRef = modal.confirm({
        title: '修改',
        wrapClassName: MODAL_CLASS_NAME, // 添加自定义类名
        content: (
          <Form form={form} onFinish={submit}>
            <FormItmeRender
              data={[
                {
                  formItemProps: {
                    label: "标题",
                    name: "customizeTitle",
                    rules: [{ required: true, message: '请输入' }]
                  },
                  render: <Input allowClear />
                },
                {
                  formItemProps: {
                    label: (
                      <Space>
                        <span>图标</span>
                        <ProblemExplanation text={'可自定义展示图标或指定颜色'} />
                      </Space>
                    ),
                    name: "customizeIcon",
                  },
                  render: <IconOrColor />
                },
                {
                  formItemProps: {
                    label: "描述",
                    name: "customizeDescription",
                  },
                  render: <Input.TextArea allowClear rows={3} />
                },
              ]}
            />
          </Form>
        ),
        footer: (
          <div style={{ textAlign: 'right', paddingTop: 10 }}>
            <Space>
              <Button loading={setConfirmLoading} onClick={() => modalRef.destroy()}>取消</Button>
              <Button loading={setConfirmLoading} onClick={reset}>重置</Button>
              <Button loading={setConfirmLoading} onClick={form.submit} type='primary'>确认</Button>
            </Space>
          </div>
        ),
        width: 500,
      })

      // 等待modal渲染完成
      setTimeout(() => {
        // 使用自定义类名获取modal的DOM元素
        const modalElement = document.querySelector(`.${MODAL_CLASS_NAME}`);

        if (modalElement) {
          const preventEvent = (e) => {
            // 阻止事件冒泡
            e.stopPropagation();
          };

          // 添加事件监听器
          modalElement.addEventListener('scroll', preventEvent);
          modalElement.addEventListener('wheel', preventEvent);
          modalElement.addEventListener('keydown', preventEvent);

          // 在modal关闭时移除事件监听器
          const originalAfterClose = modalRef.afterClose || (() => {});

          modalRef.afterClose = () => {
            modalElement.removeEventListener('scroll', preventEvent);
            modalElement.removeEventListener('wheel', preventEvent);
            modalElement.removeEventListener('keydown', preventEvent);
            originalAfterClose();
          };
        }
      }, 0)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Main
      id={DOMIds.main}
      style={{ background: background }}
      $height={height}
      $dragging={dragging}
    >
      <Carousel>
        {
          addingTheTrimmings(
            cardData?.map?.((pageData, pageIndex) => {
              return (
                <Flex key={pageIndex} justify='center' align='center' style={{ width: '100vw', height: 'calc(100vh - 90px)' }}>
                  <CardBox
                    style={{
                      position: 'relative',
                      left: (cardSezi + parseInt(gridColumnGap)) / 2 // 减去每行额外增加的一列的宽度使内容区在视觉上是居中的
                    }}
                  >
                    {
                      pageData?.map?.((col, colKey) => (
                        <Droppable
                          droppableId={`${pageIndex}-${colKey}`}
                          key={`${pageIndex}-${colKey}`}
                          type="PERSON"
                          direction='horizontal' // 排列方向
                          isDropDisabled={isOpenFolder || !col?.[0]?._id} // 是否禁用
                          isCombineEnabled={true} // 是否允许合并
                        >
                          {provided => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                display: 'grid',
                                gridTemplateColumns: `${cardSezi}px `.repeat(gridInfo.colCount) + `${cardSezi}px `, // 行 (每行额外增加一列 防止拖拽时 导致其换行 导致动画观感变差)
                                gridTemplateRows: cardSezi, //  列
                                gridColumnGap: gridColumnGap, // 行间距
                                // gridRowGap: gridRowGap, // 列间距
                                paddingBottom: gridColumnGap, // 底部填充距离
                              }}
                            >
                              {
                                col.map((row, rowKey) => {
                                  const v = row
                                  if (v?.type === 'folder' && Array.isArray(v?.data)) { // 文件夹渲染
                                    return (
                                      <Draggable
                                        draggableId={v._id}
                                        index={rowKey}
                                        key={v._id}
                                      >
                                        {provided => (
                                          <div
                                            key={`${v._id}`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() => openFolder(v)}
                                          >
                                            <FolderBox $cardSezi={cardSezi} $minCardSezi={minCardSezi} $length={v?.data?.length}>
                                              {/* 文件夹内的小卡片 */}
                                              {
                                                v?.data?.map((_v, _k) => (
                                                  <DraggableMenuCard
                                                    key={`${_v._id}`}
                                                    v={_v}
                                                    k={_k}
                                                    size={minCardSezi}
                                                    model={'min'}
                                                    isDrop={false}
                                                    disabled={isMobileDevice}
                                                  />
                                                ))
                                              }
                                            </FolderBox>
                                            <Typography.Text ellipsis={true}
                                              style={{
                                                width: '100%',
                                                textAlign: 'center',
                                              }}
                                            >{v?.title}</Typography.Text>
                                          </div>
                                        )}
                                      </Draggable>
                                    )
                                  } else if (v?._id) {
                                    // 单个图标渲染
                                    return (
                                      <DraggableMenuCard
                                        key={`${v._id}`}
                                        v={v}
                                        k={rowKey}
                                        size={cardSezi}
                                        model={'def'}
                                        isDrop={true}
                                        onMenuItemClick={onMenuItemClick}
                                        menuData={menuData}
                                        deleting={deleting}
                                        setDeleting={setDeleting}
                                        onDel={() => onDel(v)}
                                      />
                                    )
                                  } else {
                                    return null
                                  }
                                })
                              }
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      ))
                    }
                  </CardBox>
                </Flex>
              )
            })
          )
        }
      </Carousel>

      {/* 文件件内容渲染 */}
      <Folder
        takeOut={takeOut}
        cardSezi={cardSezi}
        onMenuItemClick={onMenuItemClick}
        menuData={menuData}
        onDel={onDel}
      />

      {contextHolder}
    </Main>
  )
})
