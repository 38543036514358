import React, { useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { ArrowLeft, ArrowRight, RefreshCw, ExternalLink, X, Plus } from 'lucide-react';
import Modal from '@/components/modal';
import { useRecoilState } from 'recoil'
import { openApp as _openApp, browserTabs, browserActiveTab, browserHistory, browserHistoryIndex, modalZIndex as _modalZIndex } from '@/state'
import { appIds, DOMIds, NewTab, InitTab } from '@/tools'
import Pic from '@/components/pic'

const BrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #f5f5f5;
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 8px;
  padding: 5px 5px 1px;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:hover{
    background-color: #ddd;
  }
`;

const AddressBar = styled.input`
  flex-grow: 1;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #edeff7;
`;

const TabsContainer = styled.div`
  display: flex;
  background-color: #e0e0e0;
  padding: 8px 8px 0;
  min-height: 44px;
`;

const TabsScrollBox = styled.div`
  max-width: calc(100% - 20px);
  overflow-x: auto;

  display: flex;
  flex-flow: 1;

  /* 隐藏滚动条的滑块 */
  &::-webkit-scrollbar {
    display: none;
  }

  /* 隐藏滚动条的轨道 */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`

const Tab = styled.div`
  padding: 8px 12px;
  background-color: ${props => props.$active ? '#fff' : '#f0f0f0'};
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  margin-right: 4px;
  max-width: 200px;
  display: flex;
  align-items: center;
`;

const TabTitle = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 132px;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;

  position: relative;
  
  & > iframe{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
  }
`;

// 遮罩层
const MaskLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Browser = () => {
  const [tabs, setTabs] = useRecoilState(browserTabs)// 标签数据
  const [activeTab, setActiveTab] = useRecoilState(browserActiveTab)// 当前激活的标签
  const [history, setHistory] = useRecoilState(browserHistory)// 历史记录
  const [historyIndex, setHistoryIndex] = useRecoilState(browserHistoryIndex)// 当前处于历史记录的那一条
  const [openApp] = useRecoilState(_openApp) // 打开的应用
  const [modalZIndex] = useRecoilState(_modalZIndex) // 模态窗 z-index

  // const iframeRef = useRef(null)
  const tabsScrollBox = useRef(null) // 滚动盒子

  // 地址栏url变化
  const handleUrlChange = (e) => {
    const newUrl = e.target.value;
    setTabs(tabs.map(tab =>
      tab.id === activeTab ? { ...tab, url: newUrl } : tab
    ));
  };

  // 切换tab
  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  // 添加tab
  const addTab = () => {
    const newTab = { id: Date.now(), url: '', title: NewTab };
    setTabs([...tabs, newTab]);
    setActiveTab(newTab.id);
  };

  // 关闭tab
  const closeTab = (id) => {
    const newTabs = tabs.filter(tab => tab.id !== id);
    setTabs(newTabs);

    if (activeTab === id && newTabs.length > 0) {
      setActiveTab(newTabs[newTabs.length - 1].id);
    }
  };

  // 跳转
  const navigate = (url) => {
    const newHistory = history.slice(0, historyIndex + 1);
    newHistory.push(url);
    setHistory(newHistory);
    setHistoryIndex(newHistory.length - 1);
    setTabs(tabs.map(tab => 
      tab.id === activeTab ? { ...tab, url } : tab
    ));
  };

  // 后退
  const goBack = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      const prevUrl = history[historyIndex - 1];
      setTabs(tabs.map(tab => 
        tab.id === activeTab ? { ...tab, url: prevUrl } : tab
      ));
    }
  };

  // 前进
  const goForward = () => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(historyIndex + 1);
      const nextUrl = history[historyIndex + 1];
      setTabs(tabs.map(tab => 
        tab.id === activeTab ? { ...tab, url: nextUrl } : tab
      ));
    }
  };

  // 刷新
  const refresh = () => {
    // 在真实的浏览器中，这将重新加载页面
    // 这里我们只是通过重新设置当前 URL 来模拟它
    const currentTab = tabs.find(tab => tab.id === activeTab);
    if (currentTab) {
      navigate(currentTab.url);
    }
  };

  // 在真实浏览器中打开
  const openInNewTab = () => {
    const currentTab = tabs.find(tab => tab.id === activeTab);
    if (currentTab) {
      window.open(currentTab.url, '_blank');
    }
  };

  // 模拟浏览器是否打开状态
  const isOpen = useMemo(() => {
    const res = openApp?.includes(appIds.browser)
    return res
  }, [appIds, openApp])

  // 关闭模拟浏览器时释放内存
  useEffect(() => {
    if (!isOpen) {
      setTabs([InitTab])
      setActiveTab(1)
      setHistory([])
      setHistoryIndex(-1)
    }
  }, [isOpen])

  // 监听tabs参数变化 滚动到对应tab
  useEffect(() => {
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i]
      if (tab.id === activeTab) {
        tabsScrollBox?.current?.children?.[i]?.scrollIntoView({ block: 'end' })
      }
    }

  }, [tabs, tabsScrollBox.current, activeTab])

  // 是否最高窗口
  const isHighestModal = useMemo(() => {
    if (modalZIndex[appIds.browser]) {
      // modalZIndex[appIds.browser]
      const arr = Object.entries(modalZIndex)
      arr.sort((a, b) => b[1] - a[1])

      if (arr[0][0] === appIds.browser) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }
  } ,[modalZIndex])

  return (
    <Modal
      open={isOpen}
      getContainer={`#${DOMIds.main}`}
      title={<h3>浏览器</h3>}
      isMaximized={true}
      appId={appIds.browser}
    >
      <BrowserContainer>
        <Toolbar>
          {/* 上一步 */}
          <Button onClick={goBack} disabled={historyIndex <= 0}>
            <ArrowLeft size={18} />
          </Button>

          {/* 下一步 */}
          <Button onClick={goForward} disabled={historyIndex >= history.length - 1}>
            <ArrowRight size={18} />
          </Button>

          {/* 刷新 */}
          <Button onClick={refresh}>
            <RefreshCw size={16} />
          </Button>

          {/* 地址栏 */}
          <AddressBar
            value={tabs.find(tab => tab.id === activeTab)?.url || ''}
            onChange={handleUrlChange}
            onKeyUp={(e) => e.key === 'Enter' && navigate(e.target.value)}
            // onKeyPress={(e) => e.key === 'Enter' && navigate(e.target.value)}
          />

          {/* 在浏览器中打开 */}
          <Button onClick={openInNewTab} style={{ marginLeft: 5 }}>
            <ExternalLink size={16} />
          </Button>
        </Toolbar>

        {/* 标签栏 */}
        <TabsContainer>
          {/* 标签的滚动盒子 */}
          <TabsScrollBox ref={tabsScrollBox}>
            {/* 现有标签页 */}
            {tabs.map(tab => (
              <Tab key={tab.id} $active={tab.id === activeTab} onClick={() => handleTabClick(tab.id)}>
                <Pic shape="circle" src={tab.src} backgroundColor={tab.backgroundColor} size={15} picStyle={{ marginRight: 6 }}>{tab.title}</Pic>

                <TabTitle>{tab.title}</TabTitle>

                <Button style={{ padding: '3px 3px 0', margin: '0px 0px 0px 5px' }} onClick={() => closeTab(tab.id)}>
                  <X size={12} />
                </Button>
              </Tab>
            ))}
          </TabsScrollBox>

          {/* 新增标签页 */}
          <Button onClick={addTab}>
            <Plus size={12} />
          </Button>
        </TabsContainer>

        {/* 内容容器 */}
        <Content>
          {
            tabs.map(tab => (
              <iframe
                key={tab.id}
                // ref={iframeRef}
                src={tab.url}
                style={{ width: '100%', height: '100%', border: 'none', zIndex: tab.id === activeTab ? 2 : 1 }}
                // tabs.find(tab => tab.id === activeTab)?.url
                title={tab.title}
                // onError={onError}
                // onErrorCapture={onError}
                // onLoad={onLoad}
              />
            ))
          }

          {/* 遮罩层: 用于优化 无法通过点击浏览器页面使其窗口 z-index 提升的问题 */}
          {/* 因 iframe 元素创建了独立的文档上下文 形成了事件边界 导致 iframe 的点击事件无法冒泡到 模态窗 所以在浏览器窗口 不是所有窗口中z-index最高的时 使遮罩层 附在 iframe上 来触发click 的冒泡 */}
          <MaskLayer style={{ zIndex: isHighestModal ? 1 : 3 }} />
        </Content>
      </BrowserContainer>
    </Modal>
  )
}

export default Browser