import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Input } from 'antd'

const SearchBarContainer = styled.div`
  position: sticky;
  top: 0;
  padding: 20px 20px;
  margin: 0 -20px;
  z-index: 2;

  transition: background-color 0.3s ease;
  background-color: ${props => props.isSticky ? '#fff' : 'transparent'};
`;

const SearchInput = styled(Input.Search)`
  width: 100%;
  border-radius: 10px;
  font-size: 16px;
`;

// 带滚动吸顶的搜索框
const SearchBar = ({ triggerCeilingEffectThreshold = 0, scrollContainerSelector, style, ...props }) => {
  const [isSticky, setIsSticky] = useState(false);
  const searchBarRef = useRef(null);

  useEffect(() => {
    const scrollContainer = document.querySelector(scrollContainerSelector);

    if (!scrollContainer) {
      console.error(`未找到 "${scrollContainerSelector}" 选择器对应的 element`);
      return;
    }

    const handleScroll = () => {
      if (searchBarRef.current) {
        const searchBarTop = searchBarRef.current.getBoundingClientRect().top;
        const containerTop = scrollContainer.getBoundingClientRect().top;
        const relativeTop = searchBarTop - containerTop;

        setIsSticky(relativeTop <= triggerCeilingEffectThreshold);
      }
    };

      handleScroll(); // 初始检查
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
  }, [scrollContainerSelector, triggerCeilingEffectThreshold]);

  return (
    <SearchBarContainer ref={searchBarRef} isSticky={isSticky} style={style}>
      <SearchInput type="text" placeholder="搜索" {...props} />
    </SearchBarContainer>
  );
};

export default SearchBar;