// 初始化并配置持久化
import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil'
import { getIsMobileDevice, getDetectOS, InitTab, globalExposure } from '@/tools'
import { useEffect } from 'react'


// 永久级状态
const localStorageEffect = (key) => ({ setSelf, onSet }) => {
	const savedValue = localStorage.getItem(key)
	if (savedValue != null) {
		try {
			setSelf(JSON.parse(savedValue))
		} catch (error) {
			console.log('从localStorage中初始化state报错: ', error)
		}
	}
	onSet((newValue) => {
		try {
			localStorage.setItem(key, JSON.stringify(newValue))
		} catch (error) {
			console.log('state存入localStoragee报错: ', error)
		}
	})
}

// 会话级状态
const sessionStorageEffect = (key) => ({ setSelf, onSet }) => {
	const savedValue = sessionStorage.getItem(key)

	if (savedValue != null) {
		try {
			setSelf(JSON.parse(savedValue))
		} catch (error) {
			console.log('从sessionStorage中初始化state报错: ', error)
		}
	}
	
	onSet((newValue) => {
		try {
			sessionStorage.setItem(key, JSON.stringify(newValue))
		} catch (error) {
			console.log('state存入sessionStorage报错: ', error)
		}
	})
}

// 将数据存入window下 (用于特殊情况的动态监听)
const windowEffect = (key, type) => ({ setSelf, onSet }) => {
	const savedValue = window[type].getItem(key)

	if (savedValue != null) {
		try {
			window.__app__[key] = JSON.parse(savedValue)
		} catch (error) {
			console.log('从localStorage中初始化state报错: ', error)
		}
	}
	onSet((newValue) => {
		try {
			window.__app__[key] = newValue
		} catch (error) {
			console.log('state存入window.__app__报错: ', error)
		}
	})
}

// 测试
export const test = atom({
	key: 'test',
	default: 'test',
	effects_UNSTABLE: [sessionStorageEffect('test'), localStorageEffect('test')],
})

// 是否开始严格模式
export const isStrictMode = atom({
	key: 'isStrictMode',
	default: true,
	effects_UNSTABLE: [sessionStorageEffect('isStrictMode')],
})

// 窗口尺寸
export const windowSize = atom({
	key: 'windowSize',
	default: { width: 0, height: 0 },
	effects_UNSTABLE: [sessionStorageEffect('windowSize')],
})

// 内容区域尺寸
export const mainSize = atom({
	key: 'mainSize',
	default: { width: 0, height: 0 },
	effects_UNSTABLE: [sessionStorageEffect('mainSize')],
})

// 操作系统
export const OS = atom({
	key: 'OS',
	default: getDetectOS(),
	effects_UNSTABLE: [sessionStorageEffect('OS'), localStorageEffect('OS'), windowEffect('OS', 'localStorage')],
})

// 是否移动端
export const isMobileDevice = atom({
	key: 'isMobileDevice',
	default: getIsMobileDevice(),
	effects_UNSTABLE: [sessionStorageEffect('isMobileDevice')],
})

// 布局信息
export const gridInfo = atom({
	key: 'gridInfo',
	default: {
		colCount: 10,
		rowCount: 5,
	},
	effects_UNSTABLE: [sessionStorageEffect('gridInfo')],
})

// 是否开启鼠标植入滚动轮播图
export const isSwitch = atom({
	key: 'isSwitch',
	default: false,
	effects_UNSTABLE: [sessionStorageEffect('isSwitch')],
})

// 是否打开文件夹
export const isOpenFolder = atom({
	key: 'isOpenFolder',
	default: false,
	effects_UNSTABLE: [sessionStorageEffect('isOpenFolder')],
})

// 文件夹数据
export const folderData = atom({
	key: 'folderData',
	default: {},
	effects_UNSTABLE: [sessionStorageEffect('folderData')],
})

// 当前用户全量片数据
export const cardList = atom({
	key: 'cardList',
	default: [],
	effects_UNSTABLE: [localStorageEffect('cardList'), sessionStorageEffect('cardList')],
})

// 排序后的卡片数据(扁平)
export const currentSortCardData = atom({
	key: 'currentSortCardData',
	default: [],
	effects_UNSTABLE: [localStorageEffect('currentSortCardData'), sessionStorageEffect('currentSortCardData')],
})

// 格式化后的卡片数据(排序 + 三维)
export const cardData = atom({
	key: 'cardData',
	default: [],
	effects_UNSTABLE: [localStorageEffect('cardData'), sessionStorageEffect('cardData')],
})

// 微应用数据
export const microApp = atom({
	key: 'microApp',
	default: [],
	effects_UNSTABLE: [localStorageEffect('microApp'), sessionStorageEffect('microApp')],
})

// docker栏卡片数据
export const dockerData = atom({
	key: 'dockerData',
	default: [],
	effects_UNSTABLE: [localStorageEffect('dockerData'), sessionStorageEffect('dockerData')],
})

// 当前用户拥有的卡片(_id) (派生数据)
export const haveCardList = selector({
	key: 'haveCardList',
	default: null,
	get: ({ get }) => get(cardList)?.map(v => v?.cardData?._id) || [],
})

// 是否处于拖拽状态
export const dragging = atom({
	key: 'dragging',
	default: false,
})

// 是否处于删除状态
export const deleting = atom({
	key: 'deleting',
	default: false,
	effects_UNSTABLE: [sessionStorageEffect('deleting')],
})

// 应用状态相关 ---------------------------------------------------------------------------------------------------------

// 各模态窗 zIndex 层级关系
export const modalZIndex = atom({
  key: 'modalZIndex',
  default: {},
	effects_UNSTABLE: [localStorageEffect('modalZIndex')],
})

// 打开的应用
export const openApp = atom({
  key: 'openApp',
  default: [],
	effects_UNSTABLE: [localStorageEffect('openApp')],
})

// 最小化的应用
export const minimizedApp = atom({
  key: 'minimizedApp',
  default: [],
	effects_UNSTABLE: [localStorageEffect('minimizedApp')],
})



// 浏览器相关 ---------------------------------------------------------------------------------------------------------

// 标签数据
export const browserTabs = atom({
  key: 'browserTabs',
  default: [InitTab],
	effects_UNSTABLE: [localStorageEffect('browserTabs')],
})

// 当前激活的标签
export const browserActiveTab = atom({
  key: 'browserActiveTab',
  default: 1,
	effects_UNSTABLE: [localStorageEffect('browserActiveTab')],
})

// 历史记录
export const browserHistory = atom({
  key: 'browserHistory',
  default: [],
	effects_UNSTABLE: [localStorageEffect('browserHistory')],
})

// 当前处于历史记录的那一条
export const browserHistoryIndex = atom({
  key: 'browserHistoryIndex',
  default: -1,
	effects_UNSTABLE: [localStorageEffect('browserHistoryIndex')],
})



// 用户信息相关内容 ---------------------------------------------------------------------------------------------------------

// 用户信息
export const userInfo = atom({
	key: 'userInfo',
	default: null,
	effects_UNSTABLE: [localStorageEffect('userInfo'), sessionStorageEffect('folderData'), windowEffect('userInfo', 'localStorage')],
})

// 用户信息-仅用户数据 (派生数据)
export const user = selector({
	key: 'user',
	default: null,
	get: ({ get }) => get(userInfo)?.userInfo || {},
})

// 用户排序信息 (派生数据)
export const sort = selector({
	key: 'sort',
	default: null,
	get: ({ get }) => get(userInfo)?.config?.sort || {},
})

// 图形验证码id
export const imageCaptchaId = atom({
	key: 'imageCaptchaId',
	default: '',
	effects_UNSTABLE: [sessionStorageEffect('imageCaptchaId')],
})

// 邮箱验证码id
export const eMailCaptchaId = atom({
	key: 'eMailCaptchaId',
	default: '',
	effects_UNSTABLE: [sessionStorageEffect('eMailCaptchaId')],
})

// 手机验证码id
export const phoneCaptchaId = atom({
	key: 'phoneCaptchaId',
	default: '',
	effects_UNSTABLE: [sessionStorageEffect('phoneCaptchaId')],
})

// 当前轮播图选中的页面
export const activateCarouselKey = atom({
	key: 'activateCarouselKey',
	default: 0,
	effects_UNSTABLE: [sessionStorageEffect('activateCarouselKey')],
})













// 通过编写 react 将部分状态注入到 window.__app__ 下方便在普通js 文件中(非react组件中)调用
const InjectionStatus = () => {
	const get_test = useRecoilValue(test)
  const set_test = useSetRecoilState(test)

  const get_modalZIndex = useRecoilValue(modalZIndex)
  const set_modalZIndex = useSetRecoilState(modalZIndex)

	const get_openApp = useRecoilValue(openApp)
  const set_openApp = useSetRecoilState(openApp)

	const get_minimizedApp = useRecoilValue(minimizedApp)
  const set_minimizedApp = useSetRecoilState(minimizedApp)


	const options = {
		test: {
			get: get_test,
			set: set_test,
		},

		modalZIndex: {
			get: get_modalZIndex,
			set: set_modalZIndex,
		},

		openApp: {
			get: get_openApp,
			set: set_openApp,
		},

		minimizedApp: {
			get: get_minimizedApp,
			set: set_minimizedApp,
		},

	}

  useEffect(() => {
		const getState = (key) => {
			if (options[key]) {
				return options[key].get
			} else {
				console.error(`未找到 key: ${key} 的状态, 请检查 InjectionStatus 组件内是否注册对应状态 key`)
			}
		}

		const setState = (key, val) => {
			if (options[key]) {
				options[key].set(val)
			} else {
				console.error(`未找到 key: ${key} 的状态, 请检查 InjectionStatus 组件内是否注册对应状态 key`)
			}
		}

		globalExposure('getState', getState)
		globalExposure('setState', setState)
  }, [
		get_modalZIndex,
		set_modalZIndex,
		get_openApp,
		set_openApp,
		get_minimizedApp,
		set_minimizedApp,
		get_test,
		set_test,
	]);

  return null;
}

export default InjectionStatus
