
// src/components/SearchBar.js
import React from 'react';
import styled from 'styled-components';
import Title from './Title'

const SearchBarContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
  margin: 0 -20px 20px;
  padding: 20px;
`;

const TitleBar = (props) => {
  return (
    <SearchBarContainer>
      <Title>{props.children}</Title>
    </SearchBarContainer>
  );
};

export default TitleBar;