import React, { useState, useEffect, useRef, useCallback, memo } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { X, Minus, MoveDiagonal2 } from 'lucide-react';
import { useRecoilState } from 'recoil'
import { minimizedApp as _minimizedApp, windowSize as _windowSize, mainSize as _mainSize, modalZIndex as _modalZIndex, openApp as _openApp } from '@/state'
import { modalUp } from '@/tools'

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const ModalContent = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  min-width: 300px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  backdrop-filter: blur(10px);
  z-index: 10;
`;

const ModalHeader = styled.div`
  padding: 10px;
  cursor: move;
  background-color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
`;

const ModalBody = styled.div`
  flex: 1; // 弹性盒子子元素占满剩余空间
  overflow: auto;
  padding: 1px;
  border-radius: 0 0 10px 10px;
`;

const ResizeHandle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: transparent;
  z-index: 10000;
`;

const ControlButton = styled.button`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  margin-right: 8px;
  cursor: pointer;
`;

const CloseButton = styled(ControlButton)`
  background-color: #ff5f56;
  svg{
    display: none;
  }
  &:hover svg{
    display: block;
  }
`;

const MinimizeButton = styled(ControlButton)`
  background-color: #ffbd2e;
  svg{
    display: none;
  }
  &:hover svg{
    display: block;
  }
`;

const MaximizeButton = styled(ControlButton)`
  background-color: #27c93f;
  svg{
    display: none;
  }
  &:hover svg{
    display: block;
  }
`;

const iconStyle = {
  position: 'relative',
  left: -4.5,
  top: .5,
}

const Modal = memo((props) => {
  const { open, getContainer, title, children, isMask = false, isMaximized, appId } = props
  const CORNER_SIZE = 10; // 角落(手柄)小方块的大小
  const [windowSize] = useRecoilState(_windowSize) // 窗口尺寸
  const [mainSize] = useRecoilState(_mainSize) // 启动台尺寸
  const [modalZIndex, setModalZIndex] = useRecoilState(_modalZIndex) // 模态窗 z-index
  const [position, setPosition] = useState({ x: 0, y: 0 }); // 模态框位置
  const [size, setSize] = useState({ width: windowSize.width, height: windowSize.height }); // 模态框大小
  const [_isMaximized, setIsMaximized] = useState(isMaximized); // 是否最大化
  const [isDragging, setIsDragging] = useState(false); // 是否正在拖动
  const [isResizing, setIsResizing] = useState(false); // 是否正在调整大小
  const [resizeDirection, setResizeDirection] = useState(''); // 调整大小的方向
  const modalRef = useRef(null); // 引用模态框DOM元素
  const containerRef = useRef(null); // 引用容器DOM元素
  const dragStartRef = useRef({ x: 0, y: 0 }); // 拖动开始时的位置
  const [minimizedApp, setMinimizedApp] = useRecoilState(_minimizedApp) // 最小化的应用
  const [openApp, setOpenApp] = useRecoilState(_openApp) // 打开的应用

  // 如果初始化时设置了窗口最大化 则将size修改到等同宿主DOM的尺寸
  useEffect(() => {
    if (isMaximized && containerRef.current) {
      setSize({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
      })
    }
  }, [isMaximized, containerRef.current])

  // 使用useEffect钩子设置容器元素
  useEffect(() => {
    if (getContainer) {
      const fn = (n = 0) => {
        const targetDom = document.querySelector(getContainer)
        if (targetDom) {
          containerRef.current = targetDom
        } else {
          if (n > 30) {
            containerRef.current = document.body;
          } else {
            setTimeout(() => {
              fn(n + 1)
            }, 100)
          }
        }
      }
      fn()
    } else {
      containerRef.current = document.body;
    }
  }, [getContainer]);

  // 使用useEffect钩子设置模态框位置
  useEffect(() => {
    if (open && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setPosition({
        x: Math.max(0, (rect.width - size.width) / 2),
        y: Math.max(0, (rect.height - size.height) / 2),
      });
    }
  }, [open, size]);

  // 定义拖动开始的回调函数(调整位置)
  const handleDragStart = useCallback((e) => {
    if (_isMaximized) return;
    setIsDragging(true);
    dragStartRef.current = {
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    };
  }, [_isMaximized, position]);

  // 定义拖动的回调函数
  const handleDrag = useCallback((e) => {
    if (!isDragging || _isMaximized) return;
    const containerRect = containerRef.current.getBoundingClientRect();
    const newX = e.clientX - dragStartRef.current.x;
    const newY = e.clientY - dragStartRef.current.y;
    setPosition({
      x: Math.max(0, Math.min(newX, containerRect.width - size.width)),
      y: Math.max(0, Math.min(newY, containerRect.height - size.height)),
    });
  }, [isDragging, _isMaximized, size]);

  // 定义拖动结束的回调函数
  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  // 定义调整大小开始的回调函数
  const handleResizeStart = useCallback((direction, e) => {
    if (_isMaximized) return;
    setIsResizing(true);
    setResizeDirection(direction);
    dragStartRef.current = {
      x: e.clientX,
      y: e.clientY,
      width: size.width,
      height: size.height,
      left: position.x,
      top: position.y,
    };
  }, [_isMaximized, size, position]);

  // 定义调整大小的回调函数
  const handleResize = useCallback((e) => {
    if (!isResizing || _isMaximized) return;

    const dx = e.clientX - dragStartRef.current.x;
    const dy = e.clientY - dragStartRef.current.y;
    const containerRect = containerRef.current.getBoundingClientRect();

    let newWidth = dragStartRef.current.width;
    let newHeight = dragStartRef.current.height;
    let newX = dragStartRef.current.left;
    let newY = dragStartRef.current.top;

    if (resizeDirection.includes('e')) {
      newWidth = Math.min(dragStartRef.current.width + dx, containerRect.width - dragStartRef.current.left);
    }
    if (resizeDirection.includes('w')) {
      const maxLeftShift = dragStartRef.current.left + dragStartRef.current.width - 300;
      const leftShift = Math.max(-dragStartRef.current.left, Math.min(dx, maxLeftShift));
      newWidth = dragStartRef.current.width - leftShift;
      newX = dragStartRef.current.left + leftShift;
    }
    if (resizeDirection.includes('s')) {
      newHeight = Math.min(dragStartRef.current.height + dy, containerRect.height - dragStartRef.current.top);
    }
    if (resizeDirection.includes('n')) {
      const maxTopShift = dragStartRef.current.top + dragStartRef.current.height - 200;
      const topShift = Math.max(-dragStartRef.current.top, Math.min(dy, maxTopShift));
      newHeight = dragStartRef.current.height - topShift;
      newY = dragStartRef.current.top + topShift;
    }

    setSize({ width: Math.max(300, newWidth), height: Math.max(200, newHeight) });
    setPosition({ x: newX, y: newY });
  }, [isResizing, _isMaximized, resizeDirection]);

  // 定义调整大小结束的回调函数
  const handleResizeEnd = useCallback(() => {
    setResizeDirection('');

    setIsResizing(false);
  }, [])

  // 定义最大化/还原的回调函数
  const handleMaximize = useCallback(() => {
    if (_isMaximized) {
      const width = mainSize.width * .618
      const height = mainSize.height * .9

      setSize({ width, height });
      setPosition({
        x: (containerRef.current.clientWidth - width) / 2,
        y: (containerRef.current.clientHeight - height) / 2,
      });
    } else {
      setSize({
        width: containerRef.current.clientWidth,
        height: containerRef.current.clientHeight,
      });
      setPosition({ x: 0, y: 0 });
    }
    setIsMaximized(!_isMaximized);
  }, [_isMaximized, mainSize.height, mainSize.width])

  // 最小化
  const handleMinimize = useCallback(() => {
    const arr = structuredClone(minimizedApp)
    arr.push(appId)

    setMinimizedApp([...new Set(arr)])
  }, [appId, minimizedApp, setMinimizedApp])

  // 使用useEffect钩子添加拖动事件监听
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleDrag);
      document.addEventListener('mouseup', handleDragEnd);
    }
    return () => {
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [isDragging, handleDrag, handleDragEnd]);

  // 使用useEffect钩子添加调整大小事件监听
  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleResizeEnd);
    }
    return () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [isResizing, handleResize, handleResizeEnd]);

  // 关闭模态窗
  const onClose = useCallback(() => {
    const set = new Set(openApp)
    set.delete(appId)
    const res = [...set]

    setOpenApp(res) // 利用set 的delete API删除指定数据
  }, [openApp, appId, setOpenApp])

  // 按esc关闭
  useEffect(() => {
    // 定义关闭模态窗的函数
    const handleClose = () => {
      // 可以在这里添加关闭前的逻辑，比如确认是否关闭
      onClose(); // 调用传入的onClose函数来关闭模态窗
    };

    // 定义键盘事件处理函数
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) { // 27是Esc键的键码
        handleClose();
      }
    };

    // 添加键盘事件监听器
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    // 移除事件监听器的清理函数
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, onClose])

  // 双击顶栏时 使窗口在最大化和默认大小之间切换
  const onDoubleClick = () => {
    handleMaximize()
  }

  const [zIndex, setZIndex] = useState(10);

  useEffect(() => {
    if (!modalZIndex?.[appId]) {
      setModalZIndex(prev => ({ ...prev, [appId]: zIndex }));
    } else {
      setZIndex(modalZIndex[appId]);
    }
  }, [modalZIndex, appId, setModalZIndex, zIndex]);

  // 渲染模态框组件
  if (!open || minimizedApp.includes(appId)) return null;

  const modal = (
    <ModalContent
      ref={modalRef}
      onClick={e => e.stopPropagation()}
      onMouseDown={e => modalUp({ e, modalZIndex, appId, setModalZIndex })}
      style={{
        position: 'absolute',
        left: `${position.x}px`,
        top: `${position.y}px`,
        width: `${size.width}px`,
        height: `${size.height}px`,
        zIndex,
      }}
    >
      {/* 窗口按钮 & 标题 */}
      <ModalHeader onMouseDown={handleDragStart} onDoubleClick={onDoubleClick}>
        <CloseButton onClick={onClose} onDoubleClick={e => e.stopPropagation()}>
          <X size={9} style={iconStyle} />
        </CloseButton>

        <MinimizeButton onClick={handleMinimize} onDoubleClick={e => e.stopPropagation()}>
          <Minus size={9} style={iconStyle} />
        </MinimizeButton>

        <MaximizeButton onClick={handleMaximize} onDoubleClick={e => e.stopPropagation()}>
          <MoveDiagonal2 size={9} style={iconStyle} />
        </MaximizeButton>

        <div style={{ marginLeft: '10px' }}>{title}</div>
      </ModalHeader>

      {/* 窗口内容区 */}
      <ModalBody>{children}</ModalBody>


      {!_isMaximized && (
        <>
          {/* 4个角落调整手柄 */}
          {['nw', 'ne', 'sw', 'se'].map((corner) => ( // 左上 右上 左下 右下
            <ResizeHandle
              key={corner}
              style={{
                width: `${CORNER_SIZE}px`,
                height: `${CORNER_SIZE}px`,
                top: corner.includes('n') ? 0 : 'auto',
                bottom: corner.includes('s') ? 0 : 'auto',
                left: corner.includes('w') ? 0 : 'auto',
                right: corner.includes('e') ? 0 : 'auto',
                cursor: `${corner}-resize`,
              }}
              onMouseDown={(e) => handleResizeStart(corner, e)}
            />
          ))}

          {/* 4个边缘调整手柄 */}
          {[
            { cursor: 'n-resize', direction: 'n', style: { left: CORNER_SIZE, width: `calc(100% - ${CORNER_SIZE * 2}px)`, top: 0 } }, // 上
            { cursor: 's-resize', direction: 's', style: { left: CORNER_SIZE, width: `calc(100% - ${CORNER_SIZE * 2}px)`, bottom: 0 } }, // 下
            { cursor: 'w-resize', direction: 'w', style: { top: CORNER_SIZE, height: `calc(100% - ${CORNER_SIZE * 2}px)`, left: 0 } }, // 左
            { cursor: 'e-resize', direction: 'e', style: { top: CORNER_SIZE, height: `calc(100% - ${CORNER_SIZE * 2}px)`, right: 0 } }, // 右
          ].map((v) => (
            <ResizeHandle
              key={v.direction}
              style={{ ...v.style, cursor: v.cursor }}
              onMouseDown={(e) => handleResizeStart(v.direction, e)}
            />
          ))}
        </>
      )}
    </ModalContent>
  )

  // 使用ReactDOM.createPortal将模态框渲染到指定容器中
  if (containerRef.current) {
    return ReactDOM.createPortal(
      isMask ? <ModalWrapper onClick={onClose}>{modal}</ModalWrapper> : modal,
      containerRef.current,
    )
  } else {
    return null
  }
})

export default Modal