// http 请求接口
import request from './request'

// 测试
export const testAdd = (params) => request({ url: 'test/add', params, method: 'post' })
export const testDel = (params) => request({ url: 'test/del', params, method: 'delete' })
export const testSet = (params) => request({ url: 'test/set', params, method: 'put' })
export const testGet = (params) => request({ url: 'test/get', params, method: 'get' })
export const testList = (params) => request({ url: 'test/list', params, method: 'post' })

// 菜单
export const menuAdd = (params) => request({ url: 'menu/add', params, method: 'post' })
export const menuDel = (params) => request({ url: 'menu/del', params, method: 'delete' })
export const menuSet = (params) => request({ url: 'menu/set', params, method: 'put', isFilter: false })
export const menuGet = (params) => request({ url: 'menu/get', params, method: 'get' })
export const menuList = (params) => request({ url: 'menu/list', params, method: 'post' })
export const menuAll = (params) => request({ url: 'menu/all', params, method: 'post' })
export const menuShow = (params) => request({ url: 'menu/show', params, method: 'post' })

// 字典
export const dicAdd = (params) => request({ url: 'dic/add', params, method: 'post' })
export const dicDel = (params) => request({ url: 'dic/del', params, method: 'delete' })
export const dicSet = (params) => request({ url: 'dic/set', params, method: 'put', isFilter: false })
export const dicGet = (params) => request({ url: 'dic/get', params, method: 'get' })
export const dicList = (params) => request({ url: 'dic/list', params, method: 'post' })
export const dicGetByKey = (params) => request({ url: 'dic/getByKey', params, method: 'get' }).then(res => res?.data?.data?.children || [])

// 错误
export const errAdd = (params) => request({ url: 'err/add', params, method: 'post' })
export const errDel = (params) => request({ url: 'err/del', params, method: 'delete' })
export const errSet = (params) => request({ url: 'err/set', params, method: 'put', isFilter: false })
export const errGet = (params) => request({ url: 'err/get', params, method: 'get' })
export const errList = (params) => request({ url: 'err/list', params, method: 'post' })

// 日志
export const logDel = (params) => request({ url: 'log/del', params, method: 'delete' })
export const logGet = (params) => request({ url: 'log/get', params, method: 'get' })
export const logList = (params) => request({ url: 'log/list', params, method: 'post' })

// 用户-B端
export const userAdd = (params) => request({ url: 'user/add', params, method: 'post' })
export const userDel = (params) => request({ url: 'user/del', params, method: 'delete' })
export const userSet = (params) => request({ url: 'user/set', params, method: 'put', isFilter: false })
export const userGet = (params) => request({ url: 'user/get', params, method: 'get' })
export const userList = (params) => request({ url: 'user/list', params, method: 'post' })
export const userAll = (params) => request({ url: 'user/all', params, method: 'post' })

// 用户-C端
export const userRegister = (params) => request({ url: 'user/register', params, method: 'post' }) // 注册
export const userLogIn = (params) => request({ url: 'user/login', params, method: 'get' }) // 登录
export const userForget = (params) => request({ url: 'user/forget', params, method: 'put' }) // 忘记密码
export const userImageCaptcha = (params) => request({ url: 'user/imageCaptcha', params, method: 'get' }) // 获取SVG图片验证码
export const userEMailVerificationCode = (params) => request({ url: 'user/eMailVerificationCode', params, method: 'get' }) // 获取邮箱验证码

export const userSignOut = (params) => request({ url: 'user/signOut', params, method: 'get' }) // 退出登录
export const userSave = (params) => request({ url: 'user/save', params, method: 'post' }) // 修改-C端-(不能修改邮箱/密码/手机)
export const userSetPassword = (params) => request({ url: 'user/set/password', params, method: 'post' }) // 修改密码
export const userSetPhone = (params) => request({ url: 'user/set/phone', params, method: 'post' }) // 修改手机
export const userSetEMail = (params) => request({ url: 'user/set/eMail', params, method: 'post' }) // 修改邮箱

// 用户配置
export const userConfigSave = (params) => request({ url: 'userConfig/save', params, method: 'post' }) // 保存用户配置

// 用户组
export const userGroupAdd = (params) => request({ url: 'userGroup/add', params, method: 'post' })
export const userGroupDel = (params) => request({ url: 'userGroup/del', params, method: 'delete' })
export const userGroupSet = (params) => request({ url: 'userGroup/set', params, method: 'put', isFilter: false })
export const userGroupGet = (params) => request({ url: 'userGroup/get', params, method: 'get' })
export const userGroupList = (params) => request({ url: 'userGroup/list', params, method: 'post' })
export const userGroupAll = (params) => request({ url: 'userGroup/all', params, method: 'post' })

// 权限
export const permissionsAdd = (params) => request({ url: 'permissions/add', params, method: 'post' })
export const permissionsDel = (params) => request({ url: 'permissions/del', params, method: 'delete' })
export const permissionsSet = (params) => request({ url: 'permissions/set', params, method: 'put', isFilter: false })
export const permissionsGet = (params) => request({ url: 'permissions/get', params, method: 'get' })
export const permissionsList = (params) => request({ url: 'permissions/list', params, method: 'post' })
export const permissionsAll = (params) => request({ url: 'permissions/all', params, method: 'post' })
export const permissionsuApiOptions = (params) => request({ url: 'permissions/api/options', params, method: 'get' }) // 数据权限(接口选项)

// 角色
export const roleAdd = (params) => request({ url: 'role/add', params, method: 'post' })
export const roleDel = (params) => request({ url: 'role/del', params, method: 'delete' })
export const roleSet = (params) => request({ url: 'role/set', params, method: 'put', isFilter: false })
export const roleGet = (params) => request({ url: 'role/get', params, method: 'get' })
export const roleList = (params) => request({ url: 'role/list', params, method: 'post' })
export const roleAll = (params) => request({ url: 'role/all', params, method: 'post' })

// 角色_权限关联表
export const role_permissionsSave = (params) => request({ url: 'role-permissions/save', params, method: 'post' })
export const role_permissionsFind = (params) => request({ url: 'role-permissions/find', params, method: 'post' })
export const role_permissionsDel = (params) => request({ url: 'role-permissions/del', params, method: 'delete' })
export const role_permissionsConditionalDel = (params) => request({ url: 'role-permissions/conditionalDel', params, method: 'delete' }) // 按条件删除
export const role_permissionsGet = (params) => request({ url: 'role-permissions/get', params, method: 'get' })
export const role_permissionsList = (params) => request({ url: 'role-permissions/list', params, method: 'post' })
export const role_permissionsAll = (params) => request({ url: 'role-permissions/all', params, method: 'post' })

// 用户_角色关联表
export const user_roleSave = (params) => request({ url: 'user-role/save', params, method: 'post' })
export const user_roleFind = (params) => request({ url: 'user-role/find', params, method: 'post' })
export const user_roleDel = (params) => request({ url: 'user-role/del', params, method: 'delete' })
export const user_roleConditionalDel = (params) => request({ url: 'user-role/conditionalDel', params, method: 'delete' }) // 按条件删除
export const user_roleGet = (params) => request({ url: 'user-role/get', params, method: 'get' })
export const user_roleList = (params) => request({ url: 'user-role/list', params, method: 'post' })
export const user_roleAll = (params) => request({ url: 'user-role/all', params, method: 'post' })

// 用户组_用户关联表
export const userGroup_userSave = (params) => request({ url: 'userGroup-user/save', params, method: 'post' })
export const userGroup_userFind = (params) => request({ url: 'userGroup-user/find', params, method: 'post' })
export const userGroup_userDel = (params) => request({ url: 'userGroup-user/del', params, method: 'delete' })
export const userGroup_userConditionalDel = (params) => request({ url: 'userGroup-user/conditionalDel', params, method: 'delete' }) // 按条件删除
export const userGroup_userGet = (params) => request({ url: 'userGroup-user/get', params, method: 'get' })
export const userGroup_userList = (params) => request({ url: 'userGroup-user/list', params, method: 'post' })
export const userGroup_userAll = (params) => request({ url: 'userGroup-user/all', params, method: 'post' })

// 用户组_角色关联表
export const userGroup_roleSave = (params) => request({ url: 'userGroup-role/save', params, method: 'post' })
export const userGroup_roleFind = (params) => request({ url: 'userGroup-role/find', params, method: 'post' })
export const userGroup_roleDel = (params) => request({ url: 'userGroup-role/del', params, method: 'delete' })
export const userGroup_roleConditionalDel = (params) => request({ url: 'userGroup-role/conditionalDel', params, method: 'delete' }) // 按条件删除
export const userGroup_roleGet = (params) => request({ url: 'userGroup-role/get', params, method: 'get' })
export const userGroup_roleList = (params) => request({ url: 'userGroup-role/list', params, method: 'post' })
export const userGroup_roleAll = (params) => request({ url: 'userGroup-role/all', params, method: 'post' })

// 文件
export const uploadsSave = (params) => request({ url: 'uploads/save', params, method: 'post', restConfig: { headers: { 'Content-Type': 'multipart/form-data' }}, isFilter: false }) // 上传
export const uploadsAdd = (params) => request({ url: 'uploads/add', params, method: 'post', restConfig: { headers: { 'Content-Type': 'multipart/form-data' }}, isFilter: false })
export const uploadsDel = (params) => request({ url: 'uploads/del', params, method: 'delete' })
export const uploadsSet = (params) => request({ url: 'uploads/set', params, method: 'put', isFilter: false })
export const uploadsGet = (params) => request({ url: 'uploads/get', params, method: 'get' })
export const uploadsList = (params) => request({ url: 'uploads/list', params, method: 'post' })

// 搜索类型选项(首页)
export const searchTypeAdd = (params) => request({ url: 'searchType/add', params, method: 'post' })
export const searchTypeDel = (params) => request({ url: 'searchType/del', params, method: 'delete' })
export const searchTypeSet = (params) => request({ url: 'searchType/set', params, method: 'put', isFilter: false })
export const searchTypeGet = (params) => request({ url: 'searchType/get', params, method: 'get' })
export const searchTypeList = (params) => request({ url: 'searchType/list', params, method: 'post' })
export const searchTypeAll = (params) => request({ url: 'searchType/all', params, method: 'post' })

// 分类
export const classAdd = (params) => request({ url: 'class/add', params, method: 'post' })
export const classDel = (params) => request({ url: 'class/del', params, method: 'delete' })
export const classSet = (params) => request({ url: 'class/set', params, method: 'put', isFilter: false })
export const classGet = (params) => request({ url: 'class/get', params, method: 'get' })
export const classList = (params) => request({ url: 'class/list', params, method: 'post' })
export const classAll = (params) => request({ url: 'class/all', params, method: 'post' }) // c端
export const classFindByUserId = (params) => request({ url: 'class/findByUserId', params, method: 'post' }) // 按照指定用户id及分类id查找 对应卡片 (B端)
export const classGetSortMax = (params) => request({ url: 'class/getSortMax', params, method: 'post' }) // c端
export const classDelClass = (params) => request({ url: 'class/delClass', params, method: 'delete' }) // 删 c端
export const classSetClass = (params) => request({ url: 'class/setClass', params, method: 'put', isFilter: false }) // 改 c端
export const classOfficialAll = (params) => request({ url: 'class/official/all', params, method: 'post' }) // 获取全部官方分类c端

// 卡片
export const cardAdd = (params) => request({ url: 'card/add', params, method: 'post' })
export const cardDel = (params) => request({ url: 'card/del', params, method: 'delete' })
export const cardSet = (params) => request({ url: 'card/set', params, method: 'put' })
export const cardGet = (params) => request({ url: 'card/get', params, method: 'get' })
export const cardList = (params) => request({ url: 'card/list', params, method: 'post' })
export const cardReGet = (params) => request({ url: 'card/reGet', params, method: 'post' }) // 重新获取

// 用户卡片
export const userCardAdd = (params) => request({ url: 'userCard/add', params, method: 'post' })
export const userCardDel = (params) => request({ url: 'userCard/del', params, method: 'delete' })
export const userCardSet = (params) => request({ url: 'userCard/set', params, method: 'put', isFilter: false })
export const userCardGet = (params) => request({ url: 'userCard/get', params, method: 'get' })
export const userCardList = (params) => request({ url: 'userCard/list', params, method: 'post' })
export const userCardGetSortMax = (params) => request({ url: 'userCard/getSortMax', params, method: 'post' }) // c端 排序最大的一条
export const userCardFind = (params) => request({ url: 'userCard/find', params, method: 'post', isFilter: false }) // 列表 按照当前用户id及分类id查找 对应卡片 (C端)	x
export const userCardFindByUserId = (params) => request({ url: 'userCard/findByUserId', params, method: 'post' }) // 列表 按照指定用户id及分类id查找 对应卡片 (B端)
export const userCardDelBind = (params) => request({ url: 'userCard/delBind', params, method: 'delete' }) // 删 c端
export const userCardGetCard = (params) => request({ url: 'userCard/getCard', params, method: 'get' }) // 查 c端
export const userCardSetCard = (params) => request({ url: 'userCard/setCard', params, method: 'put', isFilter: false }) // 改 c端
export const userCardGetOfficialCardByClassId = (params) => request({ url: 'userCard/getOfficialCardByClassId', params, method: 'post', isFilter: false }) // 获取类下的全部卡片 c端

// 微应用
export const microAppAdd = (params) => request({ url: 'microApp/add', params, method: 'post' })
export const microAppDel = (params) => request({ url: 'microApp/del', params, method: 'delete' })
export const microAppSet = (params) => request({ url: 'microApp/set', params, method: 'put', isFilter: false })
export const microAppGet = (params) => request({ url: 'microApp/get', params, method: 'get' })
export const microAppList = (params) => request({ url: 'microApp/list', params, method: 'post' })
export const microAppGetByUserId = (params) => request({ url: 'microApp/getByUserId', params, method: 'post' }) // c端

// 轮播图
export const carouselAdd = (params) => request({ url: 'carousel/add', params, method: 'post' })
export const carouselDel = (params) => request({ url: 'carousel/del', params, method: 'delete' })
export const carouselSet = (params) => request({ url: 'carousel/set', params, method: 'put', isFilter: false })
export const carouselGet = (params) => request({ url: 'carousel/get', params, method: 'get' })
export const carouselList = (params) => request({ url: 'carousel/list', params, method: 'post' })
export const carouselAll = (params) => request({ url: 'carousel/all', params, method: 'post' })

export * from './request'