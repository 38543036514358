import underConstruction from './UnderConstruction.png'

const UnderConstruction = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100% - 80px)'
      }}
    >
      <img
        src={underConstruction}
        alt='网站建设中'
        style={{ width: '75%', height: '75%', objectFit: 'contain' }}
      />
    </div>
  )
}

export default UnderConstruction