import styled from 'styled-components';
import { Divider } from 'antd'

const Chunk = styled.div`
  padding: 10px;
  outline: 1px solid #ddd;
  margin: 10px;
  border-radius: 10px;
  background-color: #fff;
`

const Box = (props) => {
  return <Chunk>
    <Divider children={props.title} orientation={'left'} />
    {props.children}
  </Chunk>
}

export default Box