import React, { useState, useMemo, useEffect } from 'react'
import { Image, Form, Modal, Space, message } from 'antd'
import { icon } from '@/tools'
import ImgCrop from 'antd-img-crop'
import { Upload } from 'antd'
import { userSave, uploadsSave } from '@/api'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo } from '@/state'
import { picUrl } from '@/api/request'
import styled from 'styled-components'
import Pic from '@/components/pic'

const StyledSection = styled.section`
  position: relative;
`

const StyledAvatar = styled.div`
  width: 50px;
  height: 50px;
  border: 1px solid #eee;
  cursor: pointer;
  transition: all linear 0.6s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
  overflow: hidden;

  &:hover {
    border: 1px solid #1677ff;
  }

  &::after {
    opacity: 0;
    transition: all linear 0.3s;
    content: '编 辑';
    position: absolute;
    font-size: 12px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #00000060;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover::after {
    opacity: 1;
  }
`

const StyledModalBody = styled.div`
  padding-top: 20px;
  text-align: center;
  height: 150px;

	position: relative;
	left: 38%;
	top: 21px;

  .icon {
    font-size: 33px;
    text-align: center;
    color: #666;
  }
  .desc {
    font-size: 12px;
    color: #666;
  }
`

// 图片展示+编辑
const PicDisplayEdit = () => {
  const [form] = Form.useForm()
  const [userInfo, setUserInfo] = useRecoilState(_userInfo) // 用户信息
  const [open, setOpen] = useState(false)
  const [previewSrc, setPreviewSrc] = useState() // 预览用的临时src
  const [visible, setVisible] = useState(false) // 是否展示预览浮层
  const [fileList, setFileList] = useState([]) // 已选(回显)文件列表
  const picSrc = useMemo(() => userInfo?.userInfo?.pic ? `${picUrl}/${userInfo?.userInfo?.pic}` : undefined, [userInfo]) // 用户头像

  // 图片选项change
  const onChange = ({ file, fileList: newFileList, event }) => {
    setFileList(newFileList)
  }

  // 查看图片详情
  const onPreview = async (file) => {
    let src = file?.thumbUrl || file?.url
    setPreviewSrc(src)
    setVisible(true) // 展示预览浮层
  }

  // 提交新头像
  const submit = async (formData) => {
    const { pic } = formData

    try {
      const res = await userSave({ pic })
      message.success('操作成功')
      setUserInfo(res.data)
    } catch (error) {
      // 错误处理
    }

    setOpen(false)
  }

  // 上传图片
  const customRequest = ({ file, onSuccess, onError }) => {
    const formData = new FormData()
    formData.append('file', file)

    // 判断文件大小是否超过0.5MB
    const fileSize = file.size // 文件大小，单位为字节
    const maxSize = 0.5 * 1024 * 1024 // 0.5MB的限制，转换为字节
    if (fileSize > maxSize) {
      onError('文件尺寸最大 0.5MB')
      message.error('文件尺寸最大 0.5MB')
      return
    }

    // 判断文件类型是否为jpg/jpeg/png/gif
    const fileType = file.type // 文件类型
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'] // 允许的文件类型
    if (!allowedTypes.includes(fileType.toLowerCase())) {
      onError('只允许上传jpg/jpeg/png/gif格式的图片')
      message.error('只允许上传jpg/jpeg/png/gif格式的图片')
      return
    }

    // 调用自己封装的接口函数
    uploadsSave(formData).then(res => {
      // 上传成功
      if (res?.code === 0 && res?.data?.acknowledged) {
        const { fileName } = res.data // 假设返回的URL字段为url
        form.setFieldsValue({ pic: fileName }) // 设置url字段的值
      }

      onSuccess(res)
      form.submit()
    }).catch(error => {
      // 上传失败
      console.log('error', error)
      onError(error)
    })
  }

  // 初始化
  useEffect(() => {
    if (userInfo?.userInfo?.pic && picSrc) {
      form.setFieldsValue({ pic: userInfo?.userInfo?.pic }) // 表单数据回填

      const suffixIndex = picSrc.lastIndexOf('.')
			const suffix = picSrc.split(suffixIndex)[1]

      setFileList([
        {
          uid: '-1',
          name: `image.${suffix}`,
          status: 'done',
          thumbUrl: picSrc
        }
      ]) // 头像图片回填
    }
  }, [userInfo, picSrc])

  return (
    <StyledSection>
      <StyledAvatar onClick={() => setOpen(true)}>
        {/* {picSrc ? <img src={picSrc} alt="User avatar" /> : userInfo?.userInfo?.name} */}
        <Pic src={[picSrc]} size={50} >{userInfo?.userInfo?.name}</Pic>
      </StyledAvatar>

      <Modal
        title='修改头像'
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={450}
      >
        <StyledModalBody>
          <Form form={form} onFinish={submit}>
            <Form.Item name='pic' rules={[{ required: true, message: '请上传头像' }]}>
              <ImgCrop
                rotationSlider
                showGrid
              >
                <Upload
                  listType='picture-card'
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview} // 预览
                  customRequest={customRequest}
                  style={{ width: '100%' }}
                >
                  {
                    !fileList?.length ?
                      (
                        <Space direction='vertical' size={0}>
                          <p className="icon">{icon('CloudUploadOutlined')}</p>
                          <p className="desc">点击或拖动上传</p>
                        </Space>
                      )
                      :
                      null
                  }
                </Upload>
              </ImgCrop>
            </Form.Item>
          </Form>

          {/* 图片预览 */}
          <Image
            width={200}
            style={{ display: 'none' }}
            src={previewSrc}
            preview={{
              visible,
              src: previewSrc,
              onVisibleChange: (value) => {
                setVisible(value)
              },
            }}
          />
        </StyledModalBody>
      </Modal>
    </StyledSection>
  )
}

export default PicDisplayEdit