import { useState, useEffect, useRef, memo } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useRecoilState } from 'recoil'
import { OS as _OS, isSwitch as _isSwitch, mainSize as _mainSize, gridInfo as _gridInfo, activateCarouselKey as _activateCarouselKey } from '@/state'
import { debounced } from '@/tools'
import styled from 'styled-components'

// Styled components
const CarouselContainer = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
`

const CarouselList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const CarouselItem = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  transition: all .5s;
`

const Indicator = styled.div`
  transform: translateX(-50%);
  z-index: 1;
  position: absolute;
  bottom: 10px;
  left: 50%;
`

const IndicatorItem = styled.i`
  margin: 5px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  transition: all 1s;
  opacity: ${props => props.$active ? 1 : 0.3};
`

const ArrowButton = styled.div`
  user-select: none;
  position: absolute;
  height: 100%;
  z-index: 1;
  background-color: #ffffff30;
  cursor: pointer;
  ${props => props.direction === 'prev' ? 'left: 0;' : 'right: 0;'}
  top: 0;
  width: ${props => props.width}px;
  opacity: ${props => props.$transparent ? 0 : 1};
`

const ArrowIcon = styled.i`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`

// 对轮播图data 添加额外内容(将原头部page镜像副本添加到尾部, 原尾部page镜像副本添加到头部)
// 镜像: 通过获取dom渲染后内容 转位图通过cavas绘制出来, 没采用粗暴复制react组件的的方式是为了防止轮播图的page是复制页面时因react副作用导致重复加载 全局拖拽key重复等BUG
export const addingTheTrimmings = (arr) => {
  return [
    <div key={`0-&`}>

    </div>,

    ...arr,

    <div key={`999-&`}>

    </div>,
  ]
}

const Box = memo(({ children, translateX }) => {
  return (
    <CarouselItem style={{ transform: translateX }}>
      {children}
    </CarouselItem>
  )
})

const Carousel = memo((props) => {
  const {
    children,
    isShowArrows = true, // 是否显示左右箭头
    isTransparentArrows = true, // 是否透明箭头 (透明状态下 可以点击 鼠标拖拽至箭头上仍可触发滚动)
    ..._props
  } = props

  const carouselRef = useRef(null) // 轮播图ref
  const data = useRef([]) // 轮播图 children 数据

  const [isSwitch, setIsSwitch] = useRecoilState(_isSwitch) // 是否是否按下轮播图
  const [mainSize] = useRecoilState(_mainSize) // 内容区域-尺寸
  const [OS] = useRecoilState(_OS) // 操作系统
  const carouselListRef = useRef(null) // 滚动容器
  const activateKey = useRef(0) // 轮播图当前激活的画面下标
  const [activateCarouselKey, setActivateCarouselKey] = useRecoilState(_activateCarouselKey) // 当前轮播图选中的页面
  const [translateX, setTranslateX] = useState('translateX(-100%)') // 位移的具体距离
  const isWebScrollingCooldown = useRef(false) // web滚动是否处于冷却期
  const isFingerScrollingCooldown = useRef(false) // 手指滚动是否处于冷却期
  const arrowsWidth = 34 // 箭头宽度
  const [gridInfo] = useRecoilState(_gridInfo) // 布局信息

  // 当屏幕大小发生变化时 把轮播图切换回第一页
  useEffect(() => {
    const z = 0
    activateKey.current = z // 指示器
    moveTo(z) // 轮播画面
  }, [gridInfo.colCount, gridInfo.rowCount])

  // 键盘事件
  const handleKeyDown = (e) => {
    if (e.keyCode === 37) {
      prev()
    } else if (e.keyCode === 39) {
      next()
    }
  }

  // 滚动事件(阻止滚动)
  const handleScroll = (e) => {
    carouselListRef.current.scrollLeft = 0
  }
  
  // 根据滚动方向翻页
  const handleWheel = (event) => {
    // 阻止默认行为，例如滚动页面
    // event.preventDefault()

    const mobile = ['Mac', 'iOS', 'Android']

    // 移动端左右滑动 (1 轮播图是左右方向的 / 2 手指动向就是用户意志方向 操作方向应尽量和视图一致 / 3 Mac用户使用妙控板用户习惯更接近移动端)
    if (mobile.includes(OS)) {
      if (Math.abs(event.deltaX) < Math.abs(event.deltaY)) { // 用户操作更倾向于纵向滚动
        return
      } else if (event.deltaX > 0) {
        console.log('向右滚动')
        next()
      } else {
        console.log('向左滚动')
        prev()
      }
    } else { // pc端普遍使用鼠标操作 (而鼠标的设计只有纵向滚轮 所以使用Y轴滚动操作轮播图 更符合用户习惯)
      if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) { // 用户操作更倾向于横向滚动
        return
      } else if (event.deltaY > 0) { // 向上滚动，翻到下一页
        console.log('向下滚动')
        next()
      } else { // 向下滚动，翻到上一页
        console.log('向上滚动')
        prev()
      }
    }
  }

  // 初始化注册滚动事件
  useEffect(() => {
    const element = carouselListRef.current

    element.addEventListener('scroll', handleScroll)
    element.addEventListener('wheel', e => debounced(e, handleWheel, isWebScrollingCooldown), { passive: true })
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      element.removeEventListener('scroll', handleScroll)
      element.removeEventListener('wheel', e => debounced(e, handleWheel, isWebScrollingCooldown), { passive: true })
      document.removeEventListener('keydown', handleKeyDown)
    };
  }, [])

  // 跳转指定的 key
  const moveTo = (key) => {
    setTranslateX(`translateX(-${(key + 1) * 100}%)`)
  }

  // 上一张
  const prev = () => {
    // 当前处于第一张 则去最后一张 否则去上一张
    if (activateKey.current === 0) {
      const children = carouselListRef.current.children
      for (const ele of children) {
        ele.style.transition = 'none' // 暂时去掉过渡动画
      }
      moveTo(data.current.length - 2) // 以迅雷不及掩耳盗铃之势 快到最后一个 node (初始化时在收尾各添加的 node)

      // 等待 浏览器回流之后在执行
      setTimeout(() => {
        for (const ele of children) {
          ele.style.transition = null // 恢复过度效果
        }
        clickIndicator(data.current.length - 3) // 再来一次转动给用户看
      }, 50)
    } else {
      clickIndicator(activateKey.current - 1)
    }
  }

  // 下一张
  const next = () => {
    // 当前处于最后一张 则去第一张 否则去下一张
    if (activateKey.current === data.current.length - 3) {
      const children = carouselListRef.current.children
      for (const ele of children) {
        ele.style.transition = 'none'
      }
      moveTo(-1)

      setTimeout(() => {
        for (const ele of children) {
          ele.style.transition = null
        }
        clickIndicator(0)
      }, 50)
    } else {
      clickIndicator(activateKey.current + 1)
    }
  }

  // 点击指示器
  const clickIndicator = (k) => {
    activateKey.current = k
    moveTo(k)
  }

  // 鼠标移入(上下页按钮)
  const onMouseEnterBtn = (type, e) => {
    if (!isSwitch) {
      return
    }

    switch (type) {
      case 'left':
        prev()
        break

      case 'rigth':
        next()
        break

      default:
        break
    }
  }

  const onMouseLeave = (event) => {
    // 检查 relatedTarget 是否是当前元素的子元素
    try {
      // 鼠标离开轮播图视口区域
      if (!carouselRef?.current?.contains?.(event?.relatedTarget)) {
        setIsSwitch(false)
        return
      }
    } catch (error) {
      // 排除这个错误↘
      if (error.message.includes(`Failed to execute 'contains' on 'Node': parameter`) && error.message.includes(`is not of type 'Node'`)) {
        return
      }
      console.error(error)
    }
  };

  // 手指移动
  const onTouchMove = (e) => {
    if (!isSwitch) {
      return
    }

    // 访问第一个触摸点
    const firstTouch = e.touches[0];

    // 获取触摸点的位置
    const x = firstTouch.pageX; // 获取X坐标
    const y = firstTouch.pageY; // 获取Y坐标

    const { width, height } = mainSize

    if (y < height) { // 确保手指区域在main范围内
      if (x < arrowsWidth) { // 左侧翻页区域碰撞
        debounced(e, prev, isFingerScrollingCooldown)
      } else if (x > (width - arrowsWidth)) { // 右侧翻页区域碰撞
        debounced(e, next, isFingerScrollingCooldown)
      }
    }
  }

  // 生成指示器数据
  const dataGeneration = (len) => {
    const _ = []
    for (let i = 0; i < len; i++) {
      _.push({ _id: i })
    }

    data.current = _
  }

  // 内容渲染
  const childrenRender = () => {
    if (!children) {
      dataGeneration(0)
      return null
    } else if (!children?.length) {
      dataGeneration(1)
      return <Box translateX={translateX}>{children}</Box>
    } else {
      dataGeneration(children.length)
      return children.map((v, k) => <Box key={v.key} translateX={translateX}>{v}</Box>)
    }
  }

  // 轮播图当前页 change 时存储 下标
  useEffect(() => {
    if (activateCarouselKey !== activateKey.current) {
      setActivateCarouselKey(activateKey.current)
    }
  }, [activateKey?.current])

  return (
    <CarouselContainer
      ref={carouselRef}
      onMouseDown={() => setIsSwitch(true)}
      onMouseUp={() => setIsSwitch(false)}
      onMouseLeave={onMouseLeave}
      onTouchStart={() => setIsSwitch(true)}
      onTouchEnd={() => setIsSwitch(false)}
      onTouchMove={onTouchMove}
      {..._props}
    >
      {/* 滚动容器 */}
      <CarouselList ref={carouselListRef}>
        {childrenRender()}
      </CarouselList>

      {/* 指示器 */}
      <Indicator>
        {
          data.current.length ?
            data.current.slice(1,-1).map((v, k) => (
              <IndicatorItem
                key={v._id}
                $active={activateKey.current === k}
                onClick={() => clickIndicator(k)}
              />
            )) :
            null
        }
      </Indicator>

      {
        isShowArrows && (
          <>
            {/* 上一页 */}
            <ArrowButton
              direction="prev"
              onClick={prev}
              onMouseEnter={(e) => onMouseEnterBtn('left', e)}
              width={arrowsWidth}
              $transparent={isTransparentArrows}
            >
              <ArrowIcon as={LeftOutlined} />
            </ArrowButton>

            {/* 下一页 */}
            <ArrowButton
              direction="next"
              onClick={next}
              onMouseEnter={(e) => onMouseEnterBtn('rigth', e)}
              width={arrowsWidth}
              $transparent={isTransparentArrows}
            >
              <ArrowIcon as={RightOutlined} />
            </ArrowButton>
          </>
        )
      }
    </CarouselContainer>
  )
})

export default Carousel