import { memo, useEffect, useState, useRef } from 'react'
import { Button, Skeleton } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { userCardGetOfficialCardByClassId } from '@/api'
import { AppBox } from './group'
import Card from './card'


// 搜索结果展示
const SearchResList = memo(({ keyword, setKeyword, _id, setCurrentCarouselData }) => {
  const [loading, setLoading] = useState(false) // 加载中
  const [apps, setApps] = useState([])
  const timeout = useRef()

  const fetch = async (keyword, _id) => {
    setLoading(true)

    try {
      const req = {
        // page: 1,
        // limit: 6,
        filter: {
          keyword,
          _id,
          // classId: [classData[index]._id],
        }
      }
      const res = await userCardGetOfficialCardByClassId(req)
      setApps(res?.data?.rows || [])
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  const search = (keyword, _id) => {
    if (timeout?.current) {
      clearTimeout(timeout?.current)
    }

    timeout.current = setTimeout(() => {
      fetch(keyword, _id)
    }, 500)
  }

  // 初始化
  useEffect(() => {
    if (keyword || _id) {
      search(keyword, _id)
    }
  }, [keyword, _id])

  // 返回
  const backtrack = () => {
    setKeyword(undefined)
    setLoading(false)
    setApps([])
    setCurrentCarouselData(null)
  }

  // 轮播图-详情页面
  return (
    <div>
      <header style={{ background: '#fff', marginBottom: 20, padding: 10, borderRadius: 5 }}>
        <Button color="default" variant="link" icon={<ArrowLeftOutlined />} onClick={backtrack} />
      </header>

      <AppBox>
        {
          loading ? <Skeleton active={true} /> : (
            <>
              {
                apps.map((app, index) => {
                  return (
                    <Card key={app._id} app={app} index={index} />
                  )
                })
              }
            </>
          )
        }
      </AppBox>

    </div>
  )
})

export default SearchResList
