import React from 'react';
import { useRecoilState } from 'recoil'
import { isStrictMode as _isStrictMode } from '@/state'

const StrictMode = ({ children }) => {
  const [isStrictMode] = useRecoilState(_isStrictMode)

  return (
    <>
      {
        isStrictMode ? (<React.StrictMode>{children}</React.StrictMode>) : children
      }
    </>
  )
}

export default StrictMode