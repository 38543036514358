import { useState, memo, useEffect, useMemo } from 'react'
import { Flex, Modal, Input } from 'antd'
import { useRecoilState } from 'recoil'
import { isOpenFolder as _isOpenFolder, folderData as _folderData, mainSize as _mainSize, deleting as _deleting, dockerData as _dockerData, currentSortCardData as _currentSortCardData, userInfo as _userInfo } from '@/state'
import { Droppable } from 'react-beautiful-dnd'
import { smallScreenWidthThreshold, Folder as _Folder } from '@/tools'
import { DraggableMenuCard } from '@/components/card'
import { userConfigSave } from '@/api'

const Folder = memo(props => {
  const { takeOut, cardSezi, onMenuItemClick, menuData, onDel } = props
  const [, setUserInfo] = useRecoilState(_userInfo) // 用户信息
  const [mainSize] = useRecoilState(_mainSize) // 内容区域-尺寸
  const [isOpenFolder, setIsOpenFolder] = useRecoilState(_isOpenFolder) // 是否打开文件夹
  const [folderData] = useRecoilState(_folderData) // 文件夹数据
  const [deleting, setDeleting] = useRecoilState(_deleting) // 是否处于删除状态
  const [inputVal, setInputVal] = useState('') // 文件夹名称
  const [isSwitch, setIsSwitch] = useState(false) // 鼠标/手指 按下状态

  const [currentSortCardData] = useRecoilState(_currentSortCardData) // 当前桌面排好序的(扁平化)卡片数据
  const [dockerData] = useRecoilState(_dockerData) // 底部docker栏卡片数据

  const gapSezi = { big: 40, small: 34 }
  const folder = 'folder' // 文件夹的class

  // 小屏宽度范围
  const isSmallScreen = useMemo(() => {
    return mainSize.width < smallScreenWidthThreshold
  }, [smallScreenWidthThreshold, mainSize.width])

  useEffect(() => {
    if (isOpenFolder) {
      setInputVal(folderData?.title)
    } else {
      setInputVal('')
    }
  }, [isOpenFolder])

  // 文件夹重命名
  const folderRename = (e) => {
    const newFolderRename = e.target.value

    if (newFolderRename === '' || newFolderRename === null || newFolderRename === undefined) {
      return
    }

    if (folderData?.title !== newFolderRename) {
      const req = {
        sort: {
          // 桌面排序
          desktopData: currentSortCardData?.map(v => {
              if (v?.type === _Folder) {
                return {
                  _id: v._id,
                  title: v._id === folderData._id ? newFolderRename : v.title, // 修改文件夹名称
                  type: v.type,
                  data: v?.data?.map(j => j._id)
                }
              } else {
                return v?._id
              }
          }),
          // docker栏排序
          dockerData: dockerData?.map(v => v._id)
        }
      }

      userConfigSave(req).then(res => {
        setUserInfo(res.data)
      })
    }
  }

  // 鼠标从文件夹详情里面移出
  const onMouseLeave = () => {
    // 如果鼠标处于按下状态移出里文件夹
    if (isSwitch) {
      takeOut()
      setIsOpenFolder(false)
      setIsSwitch(false)
    }
  }

  // 手指移动
  const onTouchMove = (e) => {
    if (!isSwitch) {
      return
    }

    // 访问第一个触摸点
    const firstTouch = e.touches[0];

    // 获取触摸点的位置
    const pageX = firstTouch.pageX; // 获取X坐标
    const pageY = firstTouch.pageY; // 获取Y坐标

    const rect = document.getElementsByClassName(folder)[0].getBoundingClientRect()
    const { bottom, height, left, right, top, width, x, y,} = rect

    const handle = () => {
      takeOut()
      setIsOpenFolder(false)
      setIsSwitch(false)
    }

    if (isSwitch) {
      if (pageX < left) {
        handle()
      } else if (pageX > right) {
        handle()
      } else if (pageY > bottom) {
        handle()
      } else if (pageY < top) {
        handle()
      }
    }
  }

  return (
    // {/* 文件夹 */}
    <Modal
      getContainer={'#main'}
      open={isOpenFolder}
      onOk={() => setIsOpenFolder(false)}
      onCancel={() => setIsOpenFolder(false)}
      closable={false}
      footer={null}
      width={isSmallScreen ? '90%' : '70%'}
      centered={true}
      style={{ position: 'relative', top: 100, minHeight: '70vh', paddingBottom: 50, maxWidth: 938 }}
      title={
        <Input
          variant='borderless'
          onBlur={folderRename}
          value={inputVal}
          onChange={e => setInputVal(e.target.value)}
          style={{ position: 'relative', top: -90, textAlign: 'center', color: '#fff', fontSize: 30, lineHeight: '0px' }}
        />
      }
    >
      <Droppable
        droppableId={folderData?._id}
        key={folderData?._id}
        type="PERSON"
        direction='horizontal' // 排列方向
        isCombineEnabled={false} // 是否允许合并
      >
        {
          provided => (
            <Flex justify='center'>
              <div
                className={folder}
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  padding: isSmallScreen ? '25px 25px 50px' : '45px 45px 70px' ,
                  margin: -20,
                  width: isSmallScreen ? cardSezi * 3 + gapSezi.small * 2 + 120 : null,
                }}
                onMouseLeave={onMouseLeave}
                onTouchMove={onTouchMove}
                onMouseDown={() => setIsSwitch(true)}
                onTouchStart={() => setIsSwitch(true)}
                onMouseUp={() => setIsSwitch(false)}
                onTouchEnd={() => setIsSwitch(false)}
              >
                <Flex align='center' wrap={true} gap={isSmallScreen ? gapSezi.small : gapSezi.big}>
                  {
                    folderData?.data?.map((v, k) => (
                      <div key={k} style={{ width: 70, height: 70 }}>
                        <DraggableMenuCard
                          v={v}
                          k={k}
                          size={cardSezi}
                          model={'def'}
                          isDrop={true}

                          onMenuItemClick={onMenuItemClick}
                          menuData={menuData}
                          deleting={deleting}
                          setDeleting={setDeleting}
                          onDel={() => onDel(v)}
                        />
                      </div>
                    ))
                  }
                </Flex>
                {provided.placeholder}
              </div>
            </Flex>
          )
        }
      </Droppable>
  </Modal>
  )
})

export default Folder