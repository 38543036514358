import React, { useState, memo, useEffect, useCallback } from 'react';
import { ColorPicker, Flex, Space } from 'antd'; // Input
import styled from 'styled-components';
import Pic from '@/components/pic';
import AnglePicker from '@/components/anglePicker';
import { replaceGradientDegree, isColorOrGradient, parseLinearGradient } from '@/tools';

const BorderBox = styled.div`
  padding: 5px;
  outline: 1px solid ${props => (props.$active ? '#5388f8' : '#eee')};
  display: flex;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  min-height: 50px;

  & .ant-color-picker-trigger {
    border: none !important;
    box-shadow: none !important;
  }
`;

const ColorPickerPackage = styled(ColorPicker)`
  & .ant-color-picker-color-block-inner {
    background: ${props => props.$displayColor || 'transparent'} !important;
  }
`;

const IconOrColor = memo((props) => {
  const { value, onChange, children } = props;
  const colorModeOptions = { rgb: 'rgb', linearGradient: 'linear-gradient' };

  const [active, setActive] = useState(0);
  const [colorMode, setColorMode] = useState(colorModeOptions.rgb);
  const [angle, setAngle] = useState('90deg');
  const [color, setColor] = useState(undefined);
  const [src, setSrc] = useState('');
  // const [inputVal, setInputVal] = useState();
  
  const [isInternalChange, setIsInternalChange] = useState(false); // 是否是组件内部chenge

  // 获取颜色字符串(间各种颜色类型转成字符串)
  const getColorString = (colorValue) => {
    if (!colorValue) return undefined;
    return typeof colorValue === 'string' ? colorValue : colorValue.toCssString();
  };

  // 获取(背景)颜色
  const getDisplayColor = useCallback(() => {
    const colorString = getColorString(color);
    if (!colorString) return undefined;

    if (colorMode === colorModeOptions.linearGradient) {
      return replaceGradientDegree({ toCssString: () => colorString }, angle); // ?
    }

    return colorString;
  }, [getColorString, color, colorMode, replaceGradientDegree, angle]);

  useEffect(() => {
    if (isInternalChange) {
      setIsInternalChange(false);
      return;
    }

    if (value) {
      const isColor = isColorOrGradient(value);

      if (isColor) {
        setActive(1);
        if (value.startsWith(colorModeOptions.linearGradient)) {
          const { degree: parsedAngle } = parseLinearGradient(value);
          setColorMode(colorModeOptions.linearGradient);
          setAngle(parsedAngle || '90deg');
          setColor(value);
        } else {
          setColorMode(colorModeOptions.rgb);
          setColor(value);
        }
      } else {
        setActive(0);
        // setInputVal(value)
        setSrc(value);
      }
    } else {
      setColor(undefined);
      setSrc('');
    }
  }, [value]);

  const handleInternalChange = (changes) => {
    setIsInternalChange(true);

    let finalValue = '';

    if (changes.active !== undefined && changes.active === 0) { // 选择icon
      finalValue = changes.src ?? src;
    } else {
      const currentColor = changes.color ?? color;
      const currentAngle = changes.angle ?? angle;

      if (!currentColor) {
        finalValue = undefined;
      } else {
        const colorString = getColorString(currentColor);
        if (colorString) {
          finalValue = colorString.startsWith(colorModeOptions.linearGradient)
            ? replaceGradientDegree({ toCssString: () => colorString }, currentAngle)
            : colorString;
        }
      }
    }

    onChange?.(finalValue);
    // setIsInternalChange(false);
  };

  // 颜色选择器change
  const handleColorChange = (newColor) => {
    const colorString = getColorString(newColor);
    const isGradient = colorString?.includes(colorModeOptions.linearGradient);

    setColor(newColor);
    setColorMode(isGradient ? colorModeOptions.linearGradient : colorModeOptions.rgb);

    handleInternalChange({ color: newColor });
  };

  // 角度选择器change
  const handleAngleChange = (newAngle) => {
    setAngle(newAngle);
    handleInternalChange({ angle: newAngle });
  };

  // 修改激活状态的选项
  const handleActiveChange = (newActive) => {
    setActive(newActive);
    handleInternalChange({ active: newActive });
  };

  const renderOptions = [
    {
      key: 0,
      render: (
        <Space>
          <Pic src={[src]} alt="网站图标连接" size={40} shape='square'>{children}</Pic>
          {/* <Input value={inputVal} onChange={e => setInputVal(e.target.value)} placeholder='自定义图标连接' allowClear style={{ width: 140 }} /> */}
        </Space>
      ),
    },
    {
      key: 1,
      render: (
        <>
          <ColorPickerPackage
            size='large'
            allowClear
            // showText={true}
            mode={['single', 'gradient']}
            trigger="hover"
            value={color}
            onChange={handleColorChange}
            $displayColor={getDisplayColor()}
          />
          {colorMode === colorModeOptions.linearGradient && (
            <AnglePicker size="small" value={angle} onChange={handleAngleChange} />
          )}
        </>
      ),
    },
  ];

  return (
    <Flex align="center" gap={10} style={{ outline: '1px dashed #eee', padding: 10, borderRadius: 10 }}>
      {renderOptions.map((v) => (
        <BorderBox
          key={v.key}
          $active={active === v.key}
          onClick={() => handleActiveChange(v.key)}
        >
          {v.render}
        </BorderBox>
      ))}
    </Flex>
  );
});

export default IconOrColor;