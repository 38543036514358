import { memo, useRef, useEffect, useCallback } from 'react'
import { Form, Button, Input, message, DatePicker } from 'antd'
import ShowData from '../../components/showData'
import { dateToStr, dayToTimestamp } from '@/tools'
import { userSave, userSetPassword, userSignOut } from '@/api'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo, user as _user, imageCaptchaId as _imageCaptchaId } from '@/state'
import DicSelect from '@/components/dicSelect'
import dayjs from 'dayjs'
import ImageCaptcha from '@/components/verificationCode/imageCaptcha'
import PicDisplayEdit from './picDisplayEdit'

import TitleBox from '../../components/titleBox'
import dataandpersonalization_scene_info_you_share_720x256 from '../../static/dataandpersonalization_scene_info_you_share_720x256.png'
import Ctn from '../../components/ctn'


// 用户信息
const GenderShow = memo((props) => { // 性别展示
	const { data } = props

	return <>
		{
			data === '1' ? '男' :
				data === '2' ? '女' :
					data
		}
	</>
})

const UserInfo = memo(() => {
	const childRef = useRef(null)
	const [form] = Form.useForm()
	const [, setUserInfo] = useRecoilState(_userInfo) // 用户信息
	const [user, setUser] = useRecoilState(_user) // 用户信息
	const [imageCaptchaId] = useRecoilState(_imageCaptchaId) // 图片验证码Id

	const data = [
		{
			id: 1,
			label: <PicDisplayEdit />,
			// right: '',
		},
		{
			id: 2,
			label: '用户名',
			value: user?.name,
			formConfig: {
				render: <Input allowClear />,
				formItemProps: {
					name: 'name',
					rules: [{ required: true, message: '请输入' }]
				}
			}
		},
		{
			id: 3,
			label: '密码',
			value: '••••••••',
			modalConfig: {
				formConfig: [
					{
						formItemProps: {
							rules: [{ required: true, message: '请输入' }],
							name: 'password',
							label: '密码'
						},
						render: <Input.Password allowClear />,
					},
					{
						formItemProps: {
							rules: [{ required: true, message: '请输入' }],
							name: 'newPassword',
							label: '新密码'
						},
						render: <Input.Password allowClear />,
					},
					{
						formItemProps: {
							rules: [{ required: true, message: '请输入' }],
							name: 'code',
							label: '验证码'
						},
						render: (
							<ImageCaptcha
								placeholder='图形验证码'
								ref={childRef}
							/>
						),
					},
				]
			}
		},
		{
			id: 4,
			label: '性别',
			value: <GenderShow data={user?.gender} />,
			formConfig: {
				render: <DicSelect dicKey={'gender'} />,
				formItemProps: { name: 'gender' }
			}
		},
		{
			id: 5,
			label: '生日',
			value: dateToStr(user?.birthday, 'YYYY-MM-DD'),
			formConfig: {
				render: <DatePicker allowClear style={{ width: '100%' }} placeholder='' />,
				formItemProps: { name: 'birthday' }
			}
		},
		{
			id: 6,
			label: '职业',
			value: '',
			formConfig: {
				formItemProps: { name: 'profession' }
			},
			right: '',
		},
	]

	// 单条数据修改
	const attributeSubmit = async (formData, item) => {
		const req = {}
		req[item.formConfig.formItemProps.name] = formData[item.formConfig.formItemProps.name]

		if (req.birthday) {
			req.birthday = dayToTimestamp(req.birthday)
		}

		const res = await userSave(req)
		message.success('操作成功')
		setUserInfo(res.data)
	}

	// 模态窗数据修改
	const modalSubmit = async (formData, modalFormItemData) => {
		try {
			const fields = modalFormItemData.map(v => v.formItemProps.name)

			let res = null
			// 修改密码
			if (fields.includes('password')) {
				res = await userSetPassword({ ...formData, imageCaptchaId })
			}

			setUserInfo(res.data)
			return true
		} catch (error) {
			childRef?.current?.run?.() // 重新获取验证码
			console.log(error)
			return false
		}
	}

	// 退出登录
	const signOut = useCallback(async (isRequest = true) => {
    try {
			if (isRequest) {
				await userSignOut({ _id: user?._id })
			}

			setUserInfo(null)
			setUser(null)
    } catch (error) {
			console.error(error) // 使用console.error来记录错误
		}
	}, [user, setUserInfo, setUser]); // 确保依赖项正确

	return (
		<>
			<TitleBox
				src={dataandpersonalization_scene_info_you_share_720x256}
				title={'个人信息'}
				desc={'用于展示的个人信息的，包括用户名、性别、年龄、职业等基本信息'}
			/>

			{/* 个人信息表单 */}
			<Ctn>
				<Form
					layout='vertical'
					wrapperCol={{ span: 24 }}
					form={form}
					initialValues={{ ...user, birthday: dayjs(user?.birthday) }}
				>
					<ShowData
						data={data}
						form={form}
						attributeSubmit={attributeSubmit}
						modalSubmit={modalSubmit}
					/>

					<Button block style={{ marginTop: 30 }} onClick={() => signOut(true)}>退出登录</Button>
				</Form>
			</Ctn>
		</>
	)
})

export default UserInfo