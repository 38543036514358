import axios from 'axios'
import { message } from 'antd'
import handleExceptionDic from './handleException'
import { isProduction } from '@/tools'
export const baseUrl = isProduction ? `${window.location.protocol}//api.iefi.cc:777` : 'http://127.0.0.1:777'
export const fileUrl = `${baseUrl}/uploads`
export const picUrl = `${fileUrl}/pic`

const METHOD = {
	POST: 'post',
	GET: 'get',
	PUT: 'put',
	DELETE: 'delete',
}

const axiosInstance = axios.create({
	timeout: 30000,
})

axiosInstance.defaults.baseURL = baseUrl

// request拦截器 - 注入token
axiosInstance.interceptors.request.use(
	config => {
		const userInfo = window?.__app__?.userInfo || JSON.parse(window.sessionStorage.getItem('userInfo')) || JSON.parse(window.localStorage.getItem('userInfo')) || null
		const token = userInfo?.token

		if (token) {
			config.headers.token = token
		}
		return config
	},
	err => {
		return Promise.reject(err)
	}
)

// response拦截器 - 处理特殊code
axiosInstance.interceptors.response.use((response) => {
	// 约定的 特殊 code 调用对应的处理逻辑(例: 掉线是跳转登录页面)
	const code = response?.data?.code
	if (code !== 0) {
		handleExceptionDic?.[code]?.handle?.()
	}

	// res拦截器 只执行 约定了code的处理方式的case
	// res数据返回后 进一步处理
	return response
})


/*
	去除所有 null 和 undefined 值
	移除空数组 []
	移除空字符串 ''
	对所有字符串值使用 .trim() 处理，并移除处理后为空的字符串
	递归处理嵌套对象和数组
*/
const cleanRequestParams = (obj) => {
	// Handle non-object inputs
	if (obj === null || typeof obj !== 'object') {
		return obj
	}

	// Handle arrays
	if (Array.isArray(obj)) {
		const filteredArray = obj.filter(item => {
			if (Array.isArray(item) && item.length === 0) return false
			if (item === null || item === undefined) return false
			if (typeof item === 'string' && item.trim() === '') return false
			return true
		}).map(item => cleanRequestParams(item))
    
		return filteredArray.length > 0 ? filteredArray : undefined
	}

	// Handle objects
	const result = {}
	for (const [key, value] of Object.entries(obj)) {
		let cleanValue
    
		if (typeof value === 'string') {
			cleanValue = value.trim()
			if (cleanValue === '') continue
		} else if (Array.isArray(value)) {
			cleanValue = cleanRequestParams(value)
			if (cleanValue === undefined) continue
		} else if (value === null || value === undefined) {
			continue
		} else if (typeof value === 'object') {
			cleanValue = cleanRequestParams(value)
			if (Object.keys(cleanValue).length === 0) continue
		} else {
			cleanValue = value
		}
		result[key] = cleanValue
	}
	return result
}

const request = ({ method, url, params, restConfig = {}, isFilter = true }) => {
	method = method.toLowerCase()

	if (typeof params === 'undefined') {
		params = null
	} else if (isFilter) {
		params = cleanRequestParams(params)
	}

	return new Promise((resolve, reject) => {
		const config = {
			method,
			url,
			data: method === METHOD.POST || method === METHOD.PUT ? params : null,
			params: method === METHOD.GET || method === METHOD.DELETE ? params : null,
			...restConfig,
		}
		axiosInstance(config).then(
			(res) => {
				if (res?.data?.code === 0) {
					resolve(res.data)
				} else {
					message.error(res?.data?.msg)
					reject(res?.data?.msg)
				}
			},
			(err) => {
				message.error(err?.message)
				reject(err?.message)
			},
		)
	})
}

export default request
