import React, { useMemo } from 'react'
import Modal from '@/components/modal'
import { useRecoilState } from 'recoil'
import { openApp as _openApp, userInfo as _userInfo } from '@/state'
import { appIds, DOMIds } from '@/tools'
import TwoColLayout from '@/components/twoColLayout'
import UserInfo from './settingItem/userInfo'
import ContactInformation from './settingItem/contactInformation'
import Preference from './settingItem/preference'
import { picUrl } from '@/api/request'
import NotLogin from './components/notLogin'
import { LineChartOutlined, LayoutOutlined, UserOutlined, ControlOutlined, PhoneOutlined } from '@ant-design/icons'
import UnderConstruction from '@/components/underConstruction'


const AppStore = () => {
  const [openApp] = useRecoilState(_openApp) // 打开的应用
  const [userInfo] = useRecoilState(_userInfo) // 用户信息

  const name = useMemo(() => userInfo?.userInfo?.name, [userInfo])
  const picSrc = useMemo(() => userInfo?.userInfo?.pic ? `${picUrl}/${userInfo?.userInfo?.pic}` : undefined, [userInfo]) // 用户头像

  const data = useMemo(() => {
    const res = [
      [
        !userInfo ?
          {
            id: 1,
            icon: <UserOutlined />,
            title: '登录/注册',
            subTitle: '',
            rightText: "",
            keywords: '个人信息,用户名,密码,性别,生日,职业',
            toggle: false,
            isShowArrow: true,
            content: <NotLogin />
          } :
          {
            id: 1,
            icon: picSrc,
            title: name,
            subTitle: '个人信息',
            rightText: "",
            keywords: '个人信息,用户名,密码,性别,生日,职业',
            toggle: false,
            isShowArrow: true,
            content: <UserInfo />
          },
      ],
      [],
    ]

    if (userInfo) {
      res[0].push(
        {
          id: 2,
          icon: <PhoneOutlined />,
          title: "联系方式",
          subTitle: '',
          rightText: "",
          keywords: '电子邮箱,手机号',
          toggle: false,
          isShowArrow: true,
          content: <ContactInformation />
        },
      )

      res[1].push(
        {
          id: 3,
          icon: <ControlOutlined />,
          title: "偏好设置",
          subTitle: '',
          rightText: "",
          keywords: '缓存时效,语言,个性化推荐,优化跳转',
          toggle: false,
          isShowArrow: true,
          content: <Preference />
        },
        {
          id: 4,
          icon: <LayoutOutlined />,
          title: "样式布局",
          subTitle: '',
          rightText: "",
          keywords: '',
          toggle: false,
          isShowArrow: true,
          content: <UnderConstruction />
        },
        {
          id: 5,
          icon: <LineChartOutlined />,
          title: "数据统计",
          subTitle: '',
          rightText: "",
          keywords: '',
          toggle: false,
          isShowArrow: true,
          content: <UnderConstruction />
        },
      )
    }

    return res
  }, [userInfo])

  // 偏好设置是否打开状态
  const isOpen = useMemo(() => {
    return openApp?.includes(appIds.settings)
  }, [appIds, openApp])

  return (
    <Modal
      open={isOpen}
      getContainer={`#${DOMIds.main}`}
      title={<h3>偏好设置</h3>}
      isMaximized={true}
      appId={appIds.settings}
    >
      <TwoColLayout leftflex={1} rightflex={2} data={data} isShowTitleBar={true} />
    </Modal>
  )
}

export default AppStore