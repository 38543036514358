import { memo, useState } from 'react'
import styled from 'styled-components'
import SearchBar from '../twoColLayout/components/SearchBar'
import SettingsGroup from '../twoColLayout/components/SettingsGroup'
import SettingsItem from '../twoColLayout/components/SettingsItem'
import { multidimensionalArrayFilter, DOMIds } from '@/tools'

const Ctn = styled.section`
  display: flex;
  height: 100%;
`

const Left = styled.div`
  max-height: 100%;
  background-color: #f0f0f0;
  flex: ${props => props.$leftflex || 1};
  overflow-y: auto;
  padding: 20px;
  border-right: 1px solid #d8d7dc;

  &::-webkit-scrollbar {
    display: none;
  }
`

const Right = styled.div`
  width: 100%;
  max-height: 100%;
  padding: 0 20px 20px;
  flex: ${props => props.$rightflex || 2};
  overflow-y: auto;
  background-color: #f0f0f0;

  &::-webkit-scrollbar {
    display: none;
  }

  // 抽屉
  .ant-drawer-content-wrapper{
    top: 44px;
    box-shadow: none;
    .ant-drawer-header{
      padding: 12px 16px;
    }
    .ant-drawer-body{
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`

const Elevator = memo((props) => {
  const { children, leftflex = 1, rightflex = 2, data } = props
  const [activeKey, setActiveKey] = useState(data?.[0]?.[0]?.id || 1)
  const [keyword, setKeyword] = useState('')

  return (
    <Ctn>
      <Left $leftflex={leftflex}>
        {/* 搜索栏 */}
        <SearchBar onSearch={setKeyword} allowClear={true} size='large' />

        {/* 选项 */}
        {
          multidimensionalArrayFilter(
            data,
            v =>
              v?.title?.includes(keyword) || // 主标题
              v?.subTitle?.includes(keyword) || // 副标题
              v?.keywords?.includes(keyword)) // 关键词
          ?.map?.((v, k) => (
            <SettingsGroup key={k}>
              {
                v?.map?.((itemProps) => (
                  <SettingsItem
                    isEnableAnchor={v.isEnableAnchor}
                    key={itemProps?.id}
                    {...itemProps}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                  />
                ))
              }
            </SettingsGroup>
          ))
        }
      </Left>

      <Right $rightflex={rightflex} className={DOMIds['elevator-right']}>
        {children}
      </Right>
    </Ctn>
  )
})

export default Elevator