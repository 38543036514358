import React, { useState, memo, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import SearchBar from './components/SearchBar'
import SettingsGroup from './components/SettingsGroup'
import SettingsItem from './components/SettingsItem'
import TitleBar from './components/TitleBar'
import { useRecoilState } from 'recoil'
import { userInfo as _userInfo } from '@/state'
import { multidimensionalArrayFilter } from '@/tools'


const SettingsContainer = styled.div`
  display: flex;
  height: 100%;
`;

const LeftPanel = styled.div`
  max-height: 100%;
  background-color: #f0f0f0;
  flex: ${props => props.$leftflex || 1};
  overflow-y: auto;
  padding: 20px;
  border-right: 1px solid #d8d7dc;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const RightPanel = styled.div`
  width: 100%;
  max-height: 100%;
  padding: 0 20px 20px;
  flex: ${props => props.$rightflex || 2};
  overflow-y: auto;
  background-color: #f0f0f0;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Settings = memo((props) => {
  const { leftflex = 1, rightflex = 2, data, isShowTitleBar } = props
  const [activeKey, setActiveKey] = useState(data?.[0]?.[0]?.id || 1)
  const [userInfo] = useRecoilState(_userInfo)
  const [keyword, setKeyword] = useState('')

  // 当前激活的item
  const activeItem = useMemo(() => {
    return data.flat().filter(v => v.id === activeKey)[0]
  }, [data, activeKey])

  useEffect(() => {
    if (!userInfo) {
      setActiveKey(data?.[0]?.[0]?.id)
    }
  }, [userInfo, data])

  return (
    <SettingsContainer>
      <LeftPanel $leftflex={leftflex}>
        {/* 搜索栏 */}
        <SearchBar onSearch={setKeyword} allowClear={true} size='large' />

        {/* 选项 */}
        {
          multidimensionalArrayFilter(
            data,
            v => 
              v?.title?.includes(keyword) || // 主标题
              v?.subTitle?.includes(keyword) || // 副标题
              v?.keywords?.includes(keyword)) // 关键词
          ?.map?.((v, k) => (
            <SettingsGroup key={k}>
              {
                v?.map?.((itemProps) => (
                  <SettingsItem
                    isEnableAnchor={v.isEnableAnchor}
                    key={itemProps?.id}
                    {...itemProps}
                    activeKey={activeKey}
                    setActiveKey={setActiveKey}
                  />
                ))
              }
            </SettingsGroup>
          ))
        }
      </LeftPanel>

      <RightPanel $rightflex={rightflex}>
        {/* 标题 */}
        {isShowTitleBar ? <TitleBar>{ activeItem?.subTitle || activeItem?.title}</TitleBar> : <div style={{ height: 20 }} />}

        {/* 具体内容 */}
        {activeItem?.content}
      </RightPanel>
    </SettingsContainer>
  )
})

export default Settings
