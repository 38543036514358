import { useState, useEffect, memo, useCallback } from 'react'
import styled from 'styled-components'
import Footer from './components/footer'
import Main from './components/main'
import { DragDropContext } from 'react-beautiful-dnd'

import { useRecoilState } from 'recoil'
import { isStrictMode as _isStrictMode, isOpenFolder as _isOpenFolder, folderData as _folderData, windowSize as _windowSize, activateCarouselKey as _activateCarouselKey, userInfo as _userInfo, cardList as _cardList, gridInfo as _gridInfo, cardData as _cardData, sort as _sort, currentSortCardData as _currentSortCardData, dockerData as _dockerData, dragging as _dragging, microApp as _microApp } from '@/state'

import { removeEmptyObjects, idPrefixHandle, createTo3DArray, globalExposure, Docker, Folder, sortCardData } from '@/tools'

import MicroApps from '@/microApps'
import SystemApp, { appData } from '@/apps'

import { userCardFind, userConfigSave, microAppGetByUserId } from '@/api'

const Ctx = styled.section`
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`

const Page = memo(() => {
    const [, setIsStrictMode] = useRecoilState(_isStrictMode) // 是否开启严格模式
    const [userInfo, setUserInfo] = useRecoilState(_userInfo) // 用户信息
    const [isOpenFolder] = useRecoilState(_isOpenFolder) // 是否打开文件夹
    const [gridInfo] = useRecoilState(_gridInfo) // 布局信息

    const [mainBG] = useState('') // 内容区背景 // setMainBG #bbf
    const [footerBG] = useState('') // 页脚背景 // setFooterBG #fbb
    const [ctnBG] = useState('#ccf') // 整体背景 // setCtnBG `url('https://img1.baidu.com/it/u=2802481475,3484471715&fm=253&fmt=auto&app=138&f=JPEG?w=1422&h=800')`
    const [footerHeight, setFooterHeight] = useState(90) // docker栏高度

    const [currentFolderMemo, setCurrentFolderMemo] = useState(null) // 当前拖拽的文件夹内的 标签
    const [folderData, setFolderData] = useRecoilState(_folderData) // 当前选择的文件夹数据

    const [windowSize] = useRecoilState(_windowSize) // 窗口尺寸
    const [activateCarouselKey] = useRecoilState(_activateCarouselKey) // 当前轮播图选中的页面
    const [cardList, setCardList] = useRecoilState(_cardList) // main区域卡片数据
    const [currentSortCardData, setCurrentSortCardData] = useRecoilState(_currentSortCardData) // 当前排好序的(扁平化)卡片数据
    const [cardData, setCardData] = useRecoilState(_cardData) // 格式化后的卡片数据(排好序 + 三维化)
    const [dockerData, setDockerData] = useRecoilState(_dockerData) // 底部docker栏卡片数据
    const [userConfigSort] = useRecoilState(_sort) // 用户配置的排序信息
    const [, setDragging] = useRecoilState(_dragging) // 是否处于拖拽状态
    const [microApp, setMicroApp] = useRecoilState(_microApp) // 微应用(数据)

    // 刷新桌面(获取用户卡片)
    const refreshDesktop = () => {
        setTimeout(() => { // 刚登录时 需要等待 token 写入成功后再发请求获取卡片
            userCardFind({ page: 1, limit: 1000 }).then(res => {
                setCardList([
                    ...appData,
                    ...(res?.data?.rows || [])
                ])
            })

            microAppGetByUserId().then(res => 
                setMicroApp(res?.data?.rows?.map(v => {
                    // 有冗余属性 微应用组件会报错
                    const o = {}
                    o['icon'] = v['icon']
                    o['name'] = v['name']
                    o['url'] = v['url']
                    o['iframe'] = v['iframe']
                    o['router-mode'] = v['router-mode']
                    o['inline'] = v['inline']
                    o['clear-data'] = v['clear-data']
                    o['destroy'] = v['destroy']
                    o['disable-scopecss'] = v['disable-scopecss']
                    o['disable-sandbox'] = v['disable-sandbox']
                    o['ssr'] = v['ssr']
                    o['keep-alive'] = v['keep-alive']
                    o['default-page'] = v['default-page']
                    o['baseroute'] = v['baseroute']
                    o['keep-router-state'] = v['keep-router-state']
                    o['disable-memory-router'] = v['disable-memory-router']
                    o['disable-patch-request'] = v['disable-patch-request']
                    o['fiber'] = v['fiber']
                    return o
                }) || [])
            ) // 获取微应用
        }, 100)
    }

    // 初始化当前用户卡片数据
    useEffect(() => {
        globalExposure('refreshDesktop', refreshDesktop) // 暴露刷新桌面方法

        if (userInfo) {
            refreshDesktop() // 获取桌面卡片 & 微应用
        } else {
            // 未登录时至少要把 系统的基础应用 展示出来
            setCardList(appData)
        }
    }, [userInfo])

    // 计算docker栏高度
    useEffect(() => {
        if (windowSize.height < 700) {
            setFooterHeight(64)
        } else {
            setFooterHeight(90)
        }
    }, [windowSize.height])

    // 拖拽组件加载时要取消 <严格模式> 否则 react-beautiful-dnd 无法正常运行
    useEffect(() => {
        setIsStrictMode(false)
        return () => {
            setIsStrictMode(true)
        }
    }, [setIsStrictMode])

    // 拖拽开始
    const onDragStart = (result) => {
        setDragging(true)
        console.log('onDragStart', result)
        if (isOpenFolder) {
            setCurrentFolderMemo(result)
        }
    }

    // 轮播图相关的基础数据
    const getCarouselBaseData = useCallback(() => {
        const columnCount = gridInfo.rowCount // 行数
        const rowCount = gridInfo.colCount // 列数
        const cardCount = columnCount * rowCount // 每页card数量
        const pageCount = Math.ceil(cardList.length / cardCount) // 总页数
        const currentPageData = cardData[activateCarouselKey] // 当前页数据

        return {
            columnCount,
            rowCount,
            cardCount,
            pageCount,
            cardData,
            activateCarouselKey, // 当前页面下标
            currentPageData,
        }
    },[gridInfo])

    // 拖拽结束
    const onDragEnd = (result) => {
        setDragging(false)
        console.log('onDragEnd', result)

        // 同行按照用户拖拽重新排序
        const reorder = (_cardList, result) => {
            //      每页卡片数   每行列数
            const { cardCount, rowCount } = getCarouselBaseData()

            const res = structuredClone(_cardList)

            //    当前页数      当前行数
            const [sourcePage, sourceRow] = result.source.droppableId.split('-')
            const [destinationPage, destinationRow] = result.destination.droppableId.split('-')

            let sourceIndex = -1
            let destinationIndex = -1

            if (sourcePage === Folder) { // 从文件夹中取出的卡片
                for (let i = 0; i < _cardList.length; i++) {
                    console.log('draggableId', result.draggableId)
                    if (_cardList?.[i]?._id === result.draggableId) {
                        console.log('i', i, result.draggableId)
                        sourceIndex = i
                    }
                }
            } else {
                sourceIndex = Number(cardCount) * Number(sourcePage) + // 当前页带来的 index 偏移
                Number(rowCount) * Number(sourceRow) + // 当前行带来的 index 偏移
                result.source.index // 当前列带来的 index 偏移
            }

            destinationIndex = Number(cardCount) * Number(destinationPage) + // 当前页带来的 index 偏移
                Number(rowCount) * Number(destinationRow) + // 当前行带来的 index 偏移
                result.destination.index // 当前列带来的 index 偏移

            console.log(sourceIndex, destinationIndex)

            const [removed] = res.splice(sourceIndex, 1)
            res.splice(destinationIndex, 0, removed)

            const _ = removeEmptyObjects(res)
            // console.log('重新排序', _)
            return _
        }

        // 组合文件夹 x
        const combine = (_list, result) => {
            const list = structuredClone(_list)

            // 合成目标(被碰撞目标)
            // result.combine.droppableId: "0"
            // result.combine.draggableId: "65dbfebe91ecd7859fa79346"

            // 源目标(碰撞目标)
            // result.source.droppableId: "0"
            // result.source.index: 2
            // result.draggableId: "65dc45d991ecd7859fa79408"

            /*
                0 获取对应数据 判断目标类型
                1 文件件 碰 文件夹 - 不作任何处理
                2 卡片 碰 卡片 - 组合文件夹 (发请求创建文件夹 获取_id)
                3 文件夹 碰 卡片 - 将卡片放入文件夹
                4 卡片 碰 文件夹 - 将卡片放入文件夹
            */

            let combineIndex = -1
            let sourceIndex = -1

            for (let i = 0; i < list.length; i++) {
                const v = list[i]
                if (v._id === result.draggableId) {
                    sourceIndex = i

                    if (combineIndex !== -1) {
                        break
                    }
                }

                if (v._id === result.combine.draggableId) {
                    combineIndex = i

                    if (sourceIndex !== -1) {
                        break
                    }
                }
            }

            console.log(combineIndex, sourceIndex)

            const combineData = list[combineIndex]
            const sourceData = list[sourceIndex]


            if (combineData?.type === Folder && sourceData?.type === Folder) { // 文件夹 & 文件夹 (不作任何处理) x
                return _list
            } else if (combineData?.type !== Folder && sourceData?.type !== Folder) { // 文件 & 文件 (合成文件夹) x
                list[combineIndex] = {
                    type: Folder, // 文件夹
                    _id: `${Folder}-` + Math.random(), // 文件夹必须要有唯一的 _id (todo: 发请求创建文件夹 获取_id)
                    title: '文件夹',
                    data: [
                        combineData,
                        sourceData,
                    ]
                }
                list[sourceIndex] = {}

                const _ = removeEmptyObjects(list)

                return _
                // combineData
                // sourceData
            } else {// 文件夹 & 文件     /    文件 & 文件夹 x
                if (combineData?.type !== Folder) { // 文件 碰撞 文件夹 x
                    sourceData.data.push(combineData)
                    list[combineIndex] = undefined

                    const res = removeEmptyObjects(list)
                    console.log('文件 碰撞 文件夹', res)
                    return res
                } else if (sourceData?.type !== Folder) { // 文件夹 碰撞 文件 x
                    combineData.data.push(sourceData)
                    list[sourceIndex] = undefined

                    const res = removeEmptyObjects(list)
                    console.log('文件夹 碰撞 文件', res)
                    return res
                }

                return _list
            }
        }

        if (result?.destination?.droppableId === Docker && result?.source?.droppableId === Docker) { // docker 栏卡片的排序 x
            const newDockerData = structuredClone(dockerData)

            const startIndex = result.source.index
            const endIndex = result.destination.index

            const [removed] = newDockerData.splice(startIndex, 1)
            newDockerData.splice(endIndex, 0, removed)

            const _ = removeEmptyObjects(newDockerData)
            setDockerData(_)

            sort({ cardList: currentSortCardData, dockerData: _ })
            console.log('docker 栏排序')
            return
        } else if (result?.destination?.droppableId === Docker && result?.source?.droppableId !== Docker) { // 加入 docker 栏 x
            // 先判断是否存在
            const newTarget = idPrefixHandle({ o: { _id: result?.draggableId }, prefix: Docker, type: 'add' })
            const isHit = dockerData?.find(v => v._id === newTarget._id) // 是否命中
            if (isHit) { // 已存在 不做处理 x
                console.log('docker 已存在 不做处理')
                return
            }

            const newList = structuredClone(cardList)
            const newDockerData = structuredClone(dockerData)

            if (result?.source?.droppableId?.includes(`${Folder}-`)) { // 直接从文件夹中拉 card 直接加入 docker 栏 x
                let index = -1
                for (let i = 0; i < newList.length; i++) {
                    if (newList[i]._id === result.draggableId) {
                        index = i
                        break
                    }
                }

                const cardData = newList[index] // 命中的卡片
                newDockerData.splice(result.destination.index, 0, idPrefixHandle({ o: cardData, prefix: Docker, type: 'add' }))
                const _ = removeEmptyObjects(newDockerData)
                setDockerData(_)

                console.log('从文件夹中拉 card 直接加入 docker 栏', cardData)
                sort({ cardList: currentSortCardData, dockerData: _ })
                return
            } else { // 从 main 区域 拉 card 加入 docker 数据 x
                const [currentPageIndex, currentRowIndex] = result.source.droppableId.split('-')

                //                                   页面              行                列
                const target =  cardData[currentPageIndex][currentRowIndex][result.source.index] // 当前拖拽的card
                newDockerData.splice(result?.destination?.index, 0, idPrefixHandle({ o: target, prefix: Docker, type: 'add' }))

                const _ = removeEmptyObjects(newDockerData)
                setDockerData(_)
                console.log('加入 docker 栏', target)

                sort({ cardList: currentSortCardData, dockerData: _ })
                return
            }
        } else if (result?.destination?.droppableId !== Docker && result?.source?.droppableId === Docker) { // 从 docker 栏删除 x
            const newDockerData = structuredClone(dockerData)
            newDockerData.splice(result?.source?.index, 1)

            const _ = removeEmptyObjects(newDockerData)
            setDockerData(_)
            console.log('从 docker 栏删除')

            sort({ cardList: currentSortCardData, dockerData: _ })
            return
        }

        if (result?.combine) { // 组合(所有组合相关的情况) x
            console.log('组合')

            const _ = removeEmptyObjects(
                combine(
                    currentSortCardData, // cardList,
                    result,
                    gridInfo.colCount // 列数
                )
            )
            setCardList(_)
            sort({ cardList: _, dockerData })
            return
        }

        if (!result?.destination) { // 没有移动(没目标) x
            console.log('没有移动(没目标)')
            return
        }

        if (result?.destination?.droppableId === result?.source?.droppableId && result?.destination?.index === result?.source?.index) { // 移动前后没变位置(同行 & 同下标) x
            console.log('移动前后没变位置')
            return
        }

        if (result?.destination?.droppableId === result?.source?.droppableId) { // 同行移动 x
            console.log('同行移动')

            // 文件夹内 x
            if (isOpenFolder) {
                console.log('同行移动-文件夹内')
                const newFolderData = structuredClone(folderData)
                // const newList = structuredClone(cardList)
                const newList = structuredClone(currentSortCardData)

                const startIndex = result.source.index
                const endIndex = result.destination.index

                const [removed] = newFolderData.data.splice(startIndex, 1)
                newFolderData.data.splice(endIndex, 0, removed)

                for (let index = 0; index < newList.length; index++) {
                    const i = newList[index]
                    if (i._id === newFolderData._id) {
                        newList[index] = newFolderData
                        setFolderData(newFolderData) // 同步到选中的文件夹数据
                        break
                    }
                }

                setCardList(newList)
                sort({ cardList: newList, dockerData })
                return
            } else { // 文件夹外 x
                console.log('同行移动-文件夹外')
                // const _ = reorder(cardList, result)
                const _ = reorder(currentSortCardData, result)
                setCardList(_)
                sort({ cardList: _, dockerData })
                return
            }
        } else { //  跨行移动 x
            console.log('跨行移动')
            // const _ = reorder(cardList, result)
            const _ = reorder(currentSortCardData, result)
            setCardList(_)
            sort({ cardList: _, dockerData })
            return
        }
    }

    // 取出从文件夹中拖出的标签 x
    const takeOut = () => {
        if (currentFolderMemo?.source?.droppableId) {
            // const res = structuredClone(cardList) // 克隆
            const res = structuredClone(currentSortCardData) // 克隆
            let index = -1

            for (let i = 0; i < res.length; i++) {
                // 找到文件夹在 cardList 中对应的 下标
                if (res[i]._id === currentFolderMemo?.source?.droppableId) {
                    console.log(res[i]._id, currentFolderMemo?.source?.droppableId)
                    index = i
                    break
                }
            }

            const targetFolderData = res[index]
            const target = targetFolderData.data.splice(currentFolderMemo?.source?.index, 1)[0]

            if (targetFolderData.data.length === 0) { // 文件夹已经没有数据的时候 释放文件夹 (todo: 发请求删除文件夹)
                res[index] = null
            }

            const { cardCount, activateCarouselKey } = getCarouselBaseData()
            const currentPageLastCardIndex = (activateCarouselKey + 1) * cardCount - 1 // 计算当前页最后一个 card 在整个 cardList 中的下标
            res.splice(currentPageLastCardIndex, 0, target)
            // res.push(target) // 将取出的数据加入尾部(todo: 如果当前页数据已满要触发轮播图滚动只最后一页)

            const _ = removeEmptyObjects(res)
            console.log('从文件夹中拖出标签', _)
            setCardList(_)

            sort({ cardList: _, dockerData })
        }
        setCurrentFolderMemo(null)
    }

    // 每次拖拽结束后 将排序保存到用户配置 以便下次登录时能按顺序回显
    const sort = ({ cardList, dockerData }) => {
        const req = {
            sort: {
                // 桌面排序
                desktopData: cardList?.map(v => {
                    if (v?.type === Folder) {
                        return {
                            _id: `${Folder}-${new Date().valueOf()}_${Math.random().toString(36).slice(2)}`,
                            title: v.title,
                            type: Folder,
                            data: v?.data?.map(j => j._id)
                        }
                    } else {
                        return v?._id
                    }
                }),
                // docker栏排序
                dockerData: dockerData?.map(v => v._id)
            }
        }

        userConfigSave(req).then(res => {
            setUserInfo(res.data)
        })
    }

    // <桌面卡片> 回显
    useEffect(() => {
        const _ = sortCardData(cardList, userConfigSort?.desktopData || []) // 排序
        setCurrentSortCardData(_)

        // 格式化卡片数据 并存储
        setCardData(
            // 格式化
            createTo3DArray(
                _, // sortCardData(cardList, userConfigSort?.desktopData), // cardList,
                gridInfo.colCount,
                gridInfo.rowCount,
            )
        )
    }, [gridInfo.colCount, gridInfo.rowCount, cardList, userConfigSort])

    // <docker栏卡片> 回显
    useEffect(() => {
        if (cardList?.length) {
            const memo = {}

            for (const i of cardList) {
                memo[i._id] = i
            }

            const res = userConfigSort?.dockerData?.map(_id => ({
                    ...memo[_id.split(`${Docker}-`)[1]],
                    _id,
                })
            )?.filter(v => {
                return v.cardId
            }) || []

            setDockerData(removeEmptyObjects(res))
        }
    }, [cardList, userConfigSort])

    return (
      <Ctx style={{ background: ctnBG }}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          {/* 内容区域 */}
          <Main background={mainBG} height={`calc(100vh - ${footerHeight}px)`} takeOut={takeOut} />

          {/* 下方快捷栏 */}
          <Footer background={footerBG} height={footerHeight} />
        </DragDropContext>

        {/* 全部系统应用 */}
        <SystemApp />

        {/* 微应用挂载 */}
        {microApp?.map(v => <MicroApps {...v} />)}
      </Ctx>
    )
})

export default Page
