import React, { useState, useMemo } from 'react'
import { Avatar } from 'antd'
import { isLightColor } from '@/tools'

// 头像的二次封装(src可以传入多个url 如果加载失败则加载下一张图片)
const Pic = ({ src, alt, backgroundColor, shape, picStyle, customizeIcon, ..._props }) => {
	const [currentSrcIndex, setCurrentSrcIndex] = useState(0)
	const [overflow, setOverflow] = useState(false) // 失败溢出
	const defaultSrc = ''

	const _src = useMemo(() => {
		// 数组则尝试加载
		if (Array.isArray(src)) {
			if (src[currentSrcIndex]) {
				return src[currentSrcIndex]
			} else {
				if (currentSrcIndex + 1 < src.length) {
					setCurrentSrcIndex(currentSrcIndex + 1)
				} else {
					return defaultSrc // 兜底图片
				}
			}
		} else if (typeof src === 'string') {
			// 字符串直接返回
			return src
		} else {
			return defaultSrc // 兜底图片
		}
	}, [src, currentSrcIndex])

	const handleImageError = () => {
		if (currentSrcIndex < src?.length - 1) {
			setCurrentSrcIndex(currentSrcIndex + 1)
		} else {
			// console.log('溢出')
			setOverflow(true)
		}
	}

	const _backgroundColor = useMemo(() => {
		return overflow ? backgroundColor : undefined
	}, [overflow, backgroundColor])

	const color = useMemo(() => {
		if (_backgroundColor) {
			return isLightColor(customizeIcon || _backgroundColor) ? '#000' : '#fff'
		} else {
			return undefined
		}
	}, [_backgroundColor])

	const background = useMemo(() => {
		const bg = customizeIcon || _backgroundColor
		if (bg?.startsWith?.('linear-gradient')) {
			return { backgroundImage: bg }
		} else {
			return { backgroundColor: bg }
		}
	}, [customizeIcon, _backgroundColor])

	return (
		<Avatar
			src={customizeIcon || _src} // 优先展示自定义icon 如果icon是颜色值会直接加载失败 转而以背景色的方式展示
			alt={alt}
			onError={handleImageError}
			{..._props}
			shape={shape}
			style={{
				...background,
				// background: customizeIcon || _backgroundColor,
				color,
				border: 'none',
				...picStyle,
			}}
		>
			{_props.children}
		</Avatar>
	)
}

export default Pic