// src/components/SettingsGroup.js
import React from 'react';
import styled from 'styled-components';

const GroupContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
`;

const SettingsGroup = ({ children }) => {
  return <GroupContainer>{children}</GroupContainer>;
};

export default SettingsGroup;