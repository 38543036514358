import { memo } from 'react'
import { Select } from 'antd'
import { useRequest } from 'ahooks'
import { dicGetByKey } from '@/api'

// 字典选择器
const DicSelect = memo(({ dicKey, ...props }) => {
	const { data } = useRequest(() => dicKey ? dicGetByKey({ key: dicKey }) : () => []) // 字典-字典取值类型

	return <Select allowClear options={data?.length ? data : []} {...props} />
})

export default DicSelect
