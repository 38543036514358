import { useEffect } from 'react';
import { useRecoilState } from 'recoil'
import { OS as _OS, isMobileDevice as _isMobileDevice, windowSize as _windowSize } from '@/state'
import { getDetectOS, debounce, getIsMobileDevice } from '@/tools'

// 获取用的系统 / 是否移动端 / 视口尺寸
const WindowResize = ({ children }) => {
  const [, setOS] = useRecoilState(_OS) // 系统
  const [, setIsMobileDevice] = useRecoilState(_isMobileDevice) // 是否移动端
  const [, setWindowSize] = useRecoilState(_windowSize) // 窗口尺寸

  // 监听window尺寸
  useEffect(() => {
    // 定义一个函数来处理窗口尺寸变化
    function handleResize () {
      const width = window.innerWidth // 获取窗口宽度
      const height = window.innerHeight // 获取窗口高度
      setWindowSize({ width, height })

      // 加入延时队列处理 (等浏览器 处理完之后再获取设备信息反馈)
      setTimeout(() => {
          // 重新判断 当前用户是否是移动端设备
          setIsMobileDevice(getIsMobileDevice())

          // 重新获取 操作系统信息
          setOS(getDetectOS())
      }, 0)
    }

    const fn = debounce(handleResize, 150)

    // 为窗口添加 resize 事件监听器
    window.addEventListener('resize', fn)

    return () => {
      window.removeEventListener('resize', fn)
    }
  }, [setIsMobileDevice, setOS, setWindowSize])

  return <>{children}</>;
}

export default WindowResize