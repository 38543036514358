import { memo, useState } from 'react'
import { Typography, Spin } from 'antd'
import Pic from '@/components/pic'
import { useRecoilState } from 'recoil'
import { openApp as _openApp, minimizedApp as _minimizedApp, browserTabs, browserActiveTab, browserHistory, browserHistoryIndex, modalZIndex as _modalZIndex } from '@/state'
import { modalUp } from '@/tools'
import styled from 'styled-components'
import DeleteAnimation from '@/components/deleteAnimation'
import { Draggable } from 'react-beautiful-dnd'
import Menu from '@/components/menu'

const { Text } = Typography

const CardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  width: ${props => props.$model === 'min' ? props.size : 'auto'};
  height: ${props => props.$model === 'min' ? props.size : 'auto'};

  &:hover .ant-avatar {
    transform: translate(0, -3px) scale(1.05);
    transition: all .5s;
  }
`

const StyledTitle = styled(Text)`
  text-align: center;
  width: 100%;
`

const Point = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000000;
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
`

const Card = memo((props) => {
  const {
    data,
    picProps,
    model = 'def',
    picStyle,
    disabled,
    isShowRun, // 是否展示运行状态
    deleting,
    setDeleting,
    onDel,
  } = props

  const { _id, title, url, all } = data ?? {} // description
// const [userInfo] = useRecoilState(_userInfo) // 用户信息
  const [minimizedApp, setMinimizedApp] = useRecoilState(_minimizedApp) // 最小化的应用
  const [openApp, setOpenApp] = useRecoilState(_openApp) // 打开的应用
  // const [tabs, setTabs] = useRecoilState(browserTabs) // 标签数据
  // const [activeTab, setActiveTab] = useRecoilState(browserActiveTab) // 当前激活的标签
  // const [history, setHistory] = useRecoilState(browserHistory) // 历史记录
  // const [historyIndex, setHistoryIndex] = useRecoilState(browserHistoryIndex) // 当前处于历史记录的那一条
  const [modalZIndex, setModalZIndex] = useRecoilState(_modalZIndex) // 模态窗 z-index

  const [loading, setLoading] = useState({})

  const handleOpenApp = (appId) => {
    // 未打开的状态
    if (!openApp.includes(appId)) {
      // 传入打开的 appId
      setOpenApp(v => {
        const res = structuredClone(v)
        res.push(appId)
        return res
      })
    }

    // 处于最小化状态时
    if (minimizedApp.includes(appId)) {
      // 解除最小化
      setMinimizedApp(v => {
        const s = new Set(v)
        s.delete(appId)
        return [...s]
      })
    }

    // 把当前 app z-index 提到最高
    modalUp({ modalZIndex, appId, setModalZIndex })
  }

  const onClick = async (e) => {
    e.stopPropagation()

    if (all.type === 1) { // all.type === 'systemApp' ||
      handleOpenApp(all.appId)
    } else if (all.type === 3) { // all.type === 'microApp' ||
      handleOpenApp(all.appId)
    } else {
      // 用户开启了跳转优化 并且 有备用地址是
      // if (userInfo?.config?.optimizeJump && all?.cardData?._backupUrlList?.length) {

      // 	const loadingData = { ...loading }
      // 	loadingData[_id] = true
      // 	console.log(loadingData)
      // 	setLoading(loadingData)

      // 	const urls = [url, ...all.cardData._backupUrlList.map(v => v.backupUrl)]

      // 	console.time('测试相应最快url耗时')
      // 	const _url = await findFastestURL(urls) // 查找响应速度最快的url

      // 	setLoading({})

      // 	window.open(_url)
      // 	console.timeEnd('测试相应最快url耗时')

      // 都不响应 且 又发布器地址 跳转发布器让用户自行选择
      // 	// options?.all?.cardData?._backupUrlList?._publisher
      // } else {
      window.open(url)
      /*
        // 使用 仿真浏览器 打开
        const id = `${_id}-${Date.now()}`
        setTabs(v => {
          if (v.length === 1 && v[0].id === 1) { // 不需要默认的新标签页
            return [{ id, url, title, src: picProps?.src, backgroundColor: all.cardData['theme-color'] }]

          } else {
            const arr = structuredClone(v)
            arr.push({ id, url, title, src: picProps?.src, backgroundColor: all.cardData['theme-color'] })

            return arr
          }
        })
        setActiveTab(id)
        handleOpenApp(appIds.browser)
      */

      // }
    }
  }

  return (
    <Spin spinning={loading[_id] ? true : false}>
      <CardWrapper
        onClick={disabled ? undefined : onClick}
        $model={model}
        size={picProps.size}
      >
        <DeleteAnimation deleting={deleting} setDeleting={setDeleting} onClick={onDel}>
          <Pic shape="square" {...picProps} picStyle={picStyle}>{title}</Pic>
        </DeleteAnimation>

        {model !== 'min' && <StyledTitle ellipsis={true}>{title}</StyledTitle>}

        {isShowRun && <Point />}
      </CardWrapper>
    </Spin>
  )
})

// 渲染可拖拽 带右键菜单的卡片
export const DraggableMenuCard = (options) => {
  const {
    v, // 卡片数据透传
    k, // Draggable 使用的 index
    size, // 卡片尺寸
    model = 'def', // 卡片模式
    isDrop = true, // 是否可拖拽
    isShowTitle = true, // 是否展示标题
    picStyle, // 头像样式透传
    disabled, // 是否禁用
    isShowRun, // 是否展示<运行状态>
    menuData = [], // 右键菜单数据
    onMenuItemClick = () => {}, // 右键菜单点击事件
    deleting, // 是否处于<批量删除状态>
    setDeleting, // 修改 是否处于<批量删除状态>
    onDel = () => {} // 删除事件
  } = options // deleting

  const card = (
    <Menu
      data={
        v.type !== 1 ? menuData : [] // 系统应用禁止 修改 & 删除
      }
      onMenuItemClick={(menuItem) => onMenuItemClick(menuItem, options)}
    >
      <Card
        model={model}
        isShowTitle={isShowTitle}
        data={{
          _id: v._id,
          title: v?.customizeTitle || v?.cardData?.title,
          description: v?.customizeDescription || v?.cardData?.description,
          url: v?.cardData?.url,
          all: v,
        }}
        picStyle={picStyle}
        picProps={{
          customizeIcon: v?.customizeIcon,
          src: [v?.cardData?.appleTouchIcon, v?.cardData?.icon, v?.cardData?.shortcutIcon],
          size,
          backgroundColor: v?.cardData?.['theme-color']
        }}
        disabled={disabled}
        isShowRun={isShowRun}
        deleting={deleting}
        setDeleting={setDeleting}
        onDel={() => onDel(options)}
      />
    </Menu>
  )

  if (isDrop) {
    return (
      <Draggable
        draggableId={`${v._id}`}
        index={k}
        key={`${v._id}`}
      >
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            key={`${v._id}`}
          >
            {card}
          </div>
        )}
      </Draggable>
    )
  } else {
    return card
  }
}

export default Card